import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import React, { useCallback, useEffect, useReducer } from "react";
import QRCode from "qrcode";

import { multiFactor, TotpMultiFactorGenerator, TotpSecret } from "firebase/auth";
import { errorInfo } from "@state/error";
import * as buttonStyle from "@components/Button/style.scss";
import { InputCode } from "@screens/Landing/Authorization/InputCode";
import { IErrorResponse } from "@interfaces";
import { If } from "@components/If";
import { useDispatch } from "react-redux";
import { deleteAccount } from "@state/account";

type State = {
    isFirstStep: boolean;
    isError: boolean;
    qrCodeUrl: string;
    secret?: TotpSecret;
    email: string;
};

enum ActionType {
    SetIsFirstStep = "SET_IS_FIRST_STEP",
    SetIsError = "SET_IS_ERROR",
    SetQrCodeUrl = "SET_QR_CODE_URL",
    SetSecret = "SET_SECRET",
    SetEmail = "SET_EMAIL",
}

type Action =
    | { type: ActionType.SetIsFirstStep; payload: boolean }
    | { type: ActionType.SetIsError; payload: boolean }
    | { type: ActionType.SetQrCodeUrl; payload: string }
    | { type: ActionType.SetSecret; payload: TotpSecret }
    | { type: ActionType.SetEmail; payload: string };

const initialState: State = {
    isFirstStep: true,
    isError: false,
    qrCodeUrl: "",
    secret: undefined,
    email: "",
};

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.SetIsFirstStep:
            return { ...state, isFirstStep: action.payload };
        case ActionType.SetIsError:
            return { ...state, isError: action.payload };
        case ActionType.SetQrCodeUrl:
            return { ...state, qrCodeUrl: action.payload };
        case ActionType.SetSecret:
            return { ...state, secret: action.payload };
        case ActionType.SetEmail:
            return { ...state, email: action.payload };
        default:
            return state;
    }
}

function QRCodeMethod({ backToSignInButton }: { backToSignInButton: () => void }) {
    const [state, localDispatch] = useReducer(reducer, initialState);
    const dispatch = useDispatch();
    const getTotpSecret = useCallback(async () => {
        try {
            const currentUser = window.fb.default.auth().currentUser;
            localDispatch({ type: ActionType.SetEmail, payload: currentUser.email });
            const multiFactorSession = await multiFactor(currentUser).getSession();
            const totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
            await getQRCode(totpSecret);
            localDispatch({ type: ActionType.SetSecret, payload: totpSecret });
        } catch {
            dispatch(deleteAccount());
        }
    }, []);

    const getQRCode = async (totpSecret: TotpSecret) => {
        const totpUri = totpSecret.generateQrCodeUrl(state.email, "Your App's Name");

        QRCode.toDataURL(totpUri, { width: 256 }, (err, url) => {
            if (err) {
                console.error("Error generating QR code", err);
                return;
            }

            localDispatch({ type: ActionType.SetQrCodeUrl, payload: url });
        });
    };

    const verifyCode = async (code: string) => {
        if (!state.secret) return;
        const currentUser = window.fb.default.auth().currentUser;
        try {
            const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(state.secret, code);
            await multiFactor(currentUser).enroll(multiFactorAssertion, "TOTP");
            location.reload();
        } catch (e: unknown) {
            localDispatch({ type: ActionType.SetIsError, payload: true });
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        }
    };

    useEffect(() => {
        getTotpSecret().catch((e) => {
            backToSignInButton();
            errorInfo.setErrorInfo({
                description: e.message,
            });
        });
    }, []);

    return (
        <div className={authorizationStyle.formCenter}>
            <div className={authorizationStyle.signFormContainer}>
                <div className={authorizationStyle.titleContainer}>
                    <div className={authorizationStyle.title}>Verification</div>
                    <div className={authorizationStyle.text}>
                        {state.isFirstStep ? (
                            <>
                                Scan this QR Code or use this secret Key: <span>{state.secret?.secretKey}</span>
                            </>
                        ) : (
                            <>
                                Enter 6-digit code for <span>{state.email}</span>
                            </>
                        )}
                        <If condition={!state.isFirstStep}>
                            <div className={authorizationStyle.infoContainer}>
                                <div
                                    className={authorizationStyle.buttonResetPassword}
                                    onClick={() => localDispatch({ type: ActionType.SetIsFirstStep, payload: true })}
                                >
                                    Show QR code again
                                </div>
                            </div>
                        </If>
                    </div>
                </div>
                <div className={authorizationStyle.formContainer}>
                    <div className={authorizationStyle.qrCodeContainer}>
                        {state.isFirstStep ? (
                            state.qrCodeUrl ? (
                                <img src={state.qrCodeUrl} alt="QR Code" />
                            ) : (
                                <p>Generating QR Code...</p>
                            )
                        ) : (
                            <InputCode isError={state.isError} submit={verifyCode} />
                        )}
                    </div>
                    {state.isFirstStep && (
                        <div className={authorizationStyle.buttonContainer}>
                            <button
                                type="button"
                                className={buttonStyle.buttonSubmit}
                                onClick={() => localDispatch({ type: ActionType.SetIsFirstStep, payload: false })}
                            >
                                Verify
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default QRCodeMethod;
