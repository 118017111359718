import { call, put, select, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";

import { getUser, getUsersData, loadMoreUsers, setUser, setUsers } from "@state/users";
import { getUsers, loadRegularUsers } from "@services/Users/Users";
import RootState from "@state/interfaces";
import { getTraffic, setTraffic } from "@state/traffic";
import { loadTraffic } from "@services/Traffic";

const selectActiveProject = (state: RootState) => state.activeProject;
const selectListOfUsers = (state: RootState) => state.users.listOfUsers;

export function* getUsersSaga(action: PayloadAction<string>): SagaIterator {
    const activeProject = yield select(selectActiveProject);
    if (!activeProject.publickey) return;

    const users = yield call(getUsers, action.payload, activeProject);
    yield put(setUsers(users));
}

export function* getMoreUsersSaga(action: PayloadAction<number>): SagaIterator {
    const activeProject = yield select(selectActiveProject);
    const listOfUsers = yield select(selectListOfUsers);
    const response = yield call(getUsers, undefined, activeProject, action.payload);
    yield put(setUsers([...listOfUsers, ...response]));
}

export function* getUserSaga(action: PayloadAction<string>): SagaIterator {
    if (!action.payload) return;
    const activeProject = yield select(selectActiveProject);
    if (!activeProject.publickey) return;
    const response = yield call(() => loadRegularUsers(`/${action.payload}`));
    const [user] = response;
    yield put(getTraffic(user));
    yield put(setUser(user));
}

export function* getTrafficSaga(action: any): SagaIterator {
    const { id } = action.payload;
    const response = yield call(() => loadTraffic(id));
    yield put(setTraffic(response));
}

export const UsersSagas = [
    takeEvery(getUsersData.type, getUsersSaga),
    takeEvery(loadMoreUsers.type, getMoreUsersSaga),
    takeEvery(getUser.type, getUserSaga),
    takeEvery(getTraffic.type, getTrafficSaga),
];
