import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

import { RootState } from "@state/index";
import { If } from "@components/If";
import { TServerPool } from "@screens/Settings/VPN/PoolSelectionRules/types";
import { BridgePoolModal } from "@screens/Settings/VPN/PoolSelectionRules/PoolModal";
import { editActiveProject } from "@state/activeProject";
import { getPools } from "@state/pools";
import Table from "@components/Table";
import { accumulatePools, headings } from "@screens/Settings/VPN/PoolSelectionRules/utils";
import { Modal } from "@components/Modal";
import * as settingsStyle from "@screens/Settings/style.scss";
import { ERoles } from "@screens/Settings/enums";
import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import { EModals } from "@components/Modal/types";
import * as poolStyle from "@screens/Network/Pool/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import { IconPlus } from "@common/icons";
import { TableDataType } from "@components/Table/types";

const initServerPool: TServerPool = {
    server_pool: "",
    method: "",
    user_status: "",
    protocol: "",
    user_country: "",
    user_state: "",
    user_city: "",
    username: "",
    device_id: "",
    device_type: "",
    extra_country: "",
    extra_language: "",
    matcher: "",
};

const initialState = initialStateModal(initServerPool);
const reducerServerPools = reducerModal(initialState);

const PoolSelectionRules = () => {
    const dispatch = useDispatch();
    const privatePools = useSelector((state: RootState) => state.pools.privatePools);
    const publicPools = useSelector((state: RootState) => state.pools.publicPools);
    const [modals, dispatchModal] = useReducer(reducerServerPools, initialState);
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const [body, setBody] = useState<TableDataType[]>([]);
    const [poolsOptions, setPoolsOptions] = useState<{ value: string; label: string }[]>();

    useEffect(() => {
        dispatch(getPools());
    }, [dispatch]);

    useEffect(() => {
        if (activeProject.publickey) {
            const { config } = activeProject;
            const { server_pools = [] } = config;
            const existPools = server_pools.map(({ server_pool }: { server_pool: string }) => server_pool);
            const pools = [...privatePools, ...publicPools];
            const filteredPools = pools.filter(({ name }) => !existPools.includes(name));
            const options = filteredPools.map(({ name }) => ({
                value: name,
                label: name,
            }));

            setPoolsOptions(options);
        }
    }, [activeProject, privatePools, publicPools]);

    const showEditModal = useCallback((rule: TServerPool) => {
        dispatchModal({
            type: EModals.EditModal,
            payload: rule,
        });
    }, []);

    const showDeleteModal = useCallback((rule: TServerPool) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: rule,
        });
    }, []);

    useEffect(() => {
        if (activeProject.publickey) {
            const { config } = activeProject;
            const serverPools = config.server_pools || [];
            const parsedHydraConfig = accumulatePools(serverPools, showEditModal, showDeleteModal);
            setBody(parsedHydraConfig);
        }
    }, [activeProject, showDeleteModal, showEditModal]);

    const closeModal = useCallback(() => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: initServerPool,
        });
    }, []);

    const editRule = useCallback(
        (rule: TServerPool) => {
            if (activeProject.publickey) {
                const { config } = activeProject;
                const { server_pools = [] } = config;
                const cloneServerPools: TServerPool[] = cloneDeep(server_pools);
                const indexRule = cloneServerPools
                    .filter((value: TServerPool) => value)
                    .findIndex(
                        ({ server_pool }: TServerPool) =>
                            modals.item && server_pool && server_pool === modals.item.server_pool,
                    );
                indexRule === -1 ? cloneServerPools.push(rule) : (cloneServerPools[indexRule] = rule);

                dispatch(
                    editActiveProject({
                        ...activeProject,
                        config: {
                            ...config,
                            server_pools: cloneServerPools,
                        },
                    }),
                );
            }
        },
        [activeProject, dispatch, modals.item],
    );

    const addRule = useCallback(
        (rule: TServerPool) => {
            try {
                if (activeProject.publickey) {
                    const { config } = activeProject;
                    const { server_pools = [] } = config;
                    const cloneServerPools: TServerPool[] = cloneDeep(server_pools);
                    cloneServerPools.push(rule);

                    dispatch(
                        editActiveProject({
                            ...activeProject,
                            config: {
                                ...config,
                                server_pools: cloneServerPools,
                            },
                        }),
                    );
                }

                closeModal();
            } catch (e: any) {
                window.console.error(e);
            }
        },
        [activeProject, closeModal, dispatch],
    );

    const deleteRule = () => {
        if (activeProject && modals.item.server_pool) {
            const { config } = activeProject;
            const { server_pools = [] } = config;
            const cloneServerPools = cloneDeep(server_pools);
            const indexRule = cloneServerPools
                .filter((value: TServerPool) => value)
                .findIndex(
                    ({ server_pool }: TServerPool) =>
                        modals.item && server_pool && server_pool === modals.item.server_pool,
                );
            cloneServerPools.splice(indexRule, 1);

            dispatch(
                editActiveProject({
                    ...activeProject,
                    config: {
                        ...config,
                        server_pools: cloneServerPools,
                    },
                }),
            );

            closeModal();
        }
    };

    return (
        <div className={settingsStyle.tabContainer}>
            <Table
                tableData={body}
                headings={headings}
                title="Bridge configuration"
                emptyMessage={window.locales.noRules}
            >
                <If condition={activeProject?.role !== ERoles.Support}>
                    <div className={poolStyle.extraButtons}>
                        <button
                            className={buttonStyle.buttonAdd}
                            type="submit"
                            onClick={() =>
                                dispatchModal({
                                    type: EModals.CreateModal,
                                    payload: initServerPool,
                                })
                            }
                        >
                            <IconPlus theme="blue" />
                            {window.locales.add}
                        </button>
                    </div>
                </If>
            </Table>

            {/* Add server pool */}
            <BridgePoolModal
                isOpen={modals.createModal}
                title="Add rule"
                pool={modals.item}
                close={closeModal}
                pools={poolsOptions}
                action={addRule}
                buttonName={window.locales.confirm}
            />

            {/* Edit server pool */}
            <BridgePoolModal
                buttonName={window.locales.confirm}
                isOpen={modals.editModal}
                title="Edit rule"
                pool={modals.item}
                close={closeModal}
                pools={poolsOptions}
                action={editRule}
            />

            {/* Modal deleting server pool */}
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                title={window.locales.deleteRule}
                onClose={closeModal}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteRule,
                }}
            >
                {`Do you really want to delete rule for ${modals.item?.server_pool}?`}
            </Modal>
        </div>
    );
};

export default PoolSelectionRules;
