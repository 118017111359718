import React, { ReactElement, Suspense, useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";

import GroupParameters from "@screens/Pools/Pool/Groups/PoolGroup/GroupParameters";
import PoolLocations from "@screens/Pools/Pool/Locations";
import Groups from "@screens/Pools/Pool/Groups";
import DiscoveryPools from "@screens/Pools";
import DiscoveryPool from "@screens/Pools/Pool";
import Kind from "@screens/Objects/Object";
import ObjectWrapper from "@screens/Objects/Object/ObjectWrapper";
import User from "@screens/Users/User";
import { Devices, Parameters, Purchases, Sessions } from "@screens/Users/User/Content";
import Objects from "@screens/Objects";
import MainTab from "@components/MainTab";
import PoolSelectionRules from "@screens/Settings/VPN/PoolSelectionRules";
import Log from "@screens/Log";
import { getProjects } from "@state/projects";
import VPNList from "@screens/VPN/VPN";
import Users from "@screens/Users";
import AccessUsers from "@screens/Access/Users";
import VPN from "@screens/Settings/VPN";
import Settings from "@screens/Settings";
import Dashboard from "@screens/Dashboard";
import Export from "@screens/Export/Export";
import ActiveSessions from "@screens/ActiveSessions";
import Pool from "@screens/Network/Pool";
import Profile from "@screens/Profile/Profile";
import { Pending } from "@screens/Panding";
import { Exception, NotFound } from "@screens/Error";
import LicenseKeys from "@screens/LicenseKeys";
import Network from "@screens/Network";
import Bypass from "@screens/Settings/Bypass";
import GeneralVPN from "@screens/Settings/VPN/General";
import ClientNetworks from "@screens/Settings/VPN/ClientNetworks";
import SNI from "@screens/Settings/VPN/SNI";
import { getActiveProject } from "@state/activeProject";
import { EAuthorizationStatus } from "@interfaces";
import { ConfigTheme } from "@common/style/themes/configTheme";
import PageWithMenu from "@components/PageWithMenu";
import { resetToken } from "@state/token";
import { AuthContext } from "@common/auth-context";
import RootState from "@state/interfaces";
import Offload from "@screens/Settings/VPN/Offload";
import Pools from "@screens/Network/Pools";
import Locations from "@screens/Network/Locations";
import Countries from "@screens/Network/Countries";
import OptimalLocation from "@screens/Network/Pool/Servers";
import GeneralSettings from "@screens/Settings/Parameters";
import Fireshield from "@screens/Settings/Fireshield";
import Authentifications from "@screens/Settings/Authentifications";
import Payments from "@screens/Settings/Payments";
import Members from "@screens/Settings/Members/Members";
import GeneralGraphs from "@screens/Dashboard/GeneralGraphs";
import GeoChart from "@screens/Dashboard/GeoChart";
import Main from "@screens/Profile/Main";
import AccountSecurity from "@screens/Profile/AccountSecurity/AccountSecurity";
import Features from "@screens/Features";
import PoolLocationsProject from "@screens/Network/Pool/Locations";
import ProjectList from "./new-routing/Projects";
import { getObjectByKindRequest, IObjectResponse } from "@services/Objects";
import {
    GENERAL_NAMESPACE,
    ObjectEnum,
    PermissionEnum,
    ResultResponse,
} from "@screens/Objects/Object/Object.constants";
import base64ToJson from "@common/methods/base64toJSON";
import { setObjectPermissions } from "@state/objectPermissions";
import { ProjectType } from "@screens/VPN/VPN.types";
import Servers from "@screens/Pools/Pool/Servers";
import PoolParameters from "@screens/Pools/Pool/Parameters";
import GroupServers from "@screens/Pools/Pool/Groups/PoolGroup/GroupServers";
import PoolGroup from "@screens/Pools/Pool/Groups/PoolGroup";
import PoolLocation from "@screens/Pools/Pool/Locations/PoolLocation";
import LocationParameters from "@screens/Pools/Pool/Locations/PoolLocation/LocationParameters";
import LocationServers from "@screens/Pools/Pool/Locations/PoolLocation/LocationServers";
import AuthProjects from "@screens/Auth/AuthProjects";
import AuthProject from "@screens/Auth/AuthProject/AuthProject";
import AuthConfig from "@screens/Auth/AuthConfig";
import AuthUser from "@screens/Auth/AuthUser";
import AuthEntitlements from "@screens/Auth/AuthEntitlements";
import AuthUserParameters from "@screens/Auth/AuthUser/Parameters/AuthUserParameters";
import AuthUserGrants from "@screens/Auth/AuthUser/AuthUserGrants";
import AuthUserEntitlements from "@screens/Auth/AuthUser/AuthUserEntitlements";
import AuthUserContainer from "@screens/Auth/AuthUser/Container";
import ItAssets from "@screens/ItAssets";
import Access from "@screens/Access";
import Roles from "@screens/Access/Roles";
import OverridesScreen from "@screens/Overrides/Overrides";
import Override from "@screens/Overrides/Override/Override";
import OverrideProjects from "@screens/Overrides/OverrideProjects";
import SessionsObjects from "@screens/SessionsObjects";
import VPNCredentials from "@screens/VPNCredentials";

const listOfPages = [
    ObjectEnum.FEATURE,
    ObjectEnum.NAMESPACE,
    ObjectEnum.CLIENT_CONFIG_OVERRIDE,
    ObjectEnum.SESSION,
    ObjectEnum.DISCOVERY_POOL_CONFIG,
    ObjectEnum.CREDENTIALS,
    ObjectEnum.ROLE,
    ObjectEnum.PROJECT_AUTH_CONFIG,
    ObjectEnum.NODE,
    ObjectEnum.GROUP,
];

const PageWithObject = () => {
    const account = useSelector((state: RootState) => state.account);
    if (!account.authorized) {
        return <Pending />;
    }

    return (
        <Routes>
            <Route path=":namespace" element={<ObjectWrapper />}>
                <Route index element={<Objects />} />
                <Route path=":kind">
                    <Route index element={<Objects />} />
                    <Route path=":name" element={<Kind />} />
                </Route>
            </Route>
        </Routes>
    );
};

const getListPermission = (permissions: string[]) => permissions && permissions.includes(PermissionEnum.LIST);

const PageWithNamespace = (): ReactElement | null => {
    const objectPermissions = useSelector((state: RootState) => state.objectPermissions);
    if (!objectPermissions.namespace.includes(PermissionEnum.GET)) return null;

    return (
        <Routes>
            {getListPermission(objectPermissions.feature) && (
                <Route
                    path="features"
                    element={
                        <Suspense fallback={<Pending />}>
                            <Features />
                        </Suspense>
                    }
                />
            )}
            {getListPermission(objectPermissions[ObjectEnum.CLIENT_CONFIG_OVERRIDE]) && (
                <Route path="overrides">
                    <Route
                        index
                        element={
                            <Suspense fallback={<Pending />}>
                                <OverrideProjects />
                            </Suspense>
                        }
                    />
                    <Route path=":project">
                        <Route
                            index
                            element={
                                <Suspense fallback={<Pending />}>
                                    <OverridesScreen />
                                </Suspense>
                            }
                        />
                        <Route path=":override" element={<Override />} />
                    </Route>
                </Route>
            )}
            {getListPermission(objectPermissions[ObjectEnum.SESSION]) && (
                <Route path="sessions">
                    <Route
                        index
                        element={
                            <Suspense fallback={<Pending />}>
                                <SessionsObjects />
                            </Suspense>
                        }
                    />
                </Route>
            )}
            {getListPermission(objectPermissions[ObjectEnum.CREDENTIALS]) && (
                <Route path="credentials">
                    <Route
                        index
                        element={
                            <Suspense fallback={<Pending />}>
                                <VPNCredentials />
                            </Suspense>
                        }
                    />
                </Route>
            )}
            {getListPermission(objectPermissions[ObjectEnum.DISCOVERY_POOL_CONFIG]) && (
                <Route path="pools">
                    <Route
                        index
                        element={
                            <Suspense fallback={<Pending />}>
                                <DiscoveryPools />
                            </Suspense>
                        }
                    />
                    <Route path=":pool" element={<DiscoveryPool />}>
                        <Route index element={<Navigate replace to="parameters" />} />
                        <Route path="parameters" element={<PoolParameters />} />
                        <Route path="servers" element={<Servers />} />
                        <Route path="groups">
                            <Route
                                index
                                element={
                                    <Suspense fallback={<Pending />}>
                                        <Groups />
                                    </Suspense>
                                }
                            />
                            <Route path=":group" element={<PoolGroup />}>
                                <Route index element={<Navigate replace to="parameters" />} />
                                <Route path="parameters" element={<GroupParameters />} />
                                <Route path="servers" element={<GroupServers />} />
                            </Route>
                        </Route>
                        <Route path="locations">
                            <Route
                                index
                                element={
                                    <Suspense fallback={<Pending />}>
                                        <PoolLocations />
                                    </Suspense>
                                }
                            />
                            <Route path=":location" element={<PoolLocation />}>
                                <Route index element={<Navigate replace to="parameters" />} />
                                <Route path="parameters" element={<LocationParameters />} />
                                <Route path="servers" element={<LocationServers />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            )}
            {getListPermission(objectPermissions[ObjectEnum.ROLE]) && (
                <Route path="access" element={<Access />}>
                    <Route index element={<Navigate replace to="roles" />} />
                    <Route path="roles" element={<Roles />} />
                    <Route path="users" element={<AccessUsers />} />
                </Route>
            )}
            <Route path="namespaces">
                <Route index element={<Objects />} />
                <Route path="*" element={<PageWithObject />} />
            </Route>
            {getListPermission(objectPermissions[ObjectEnum.PROJECT_AUTH_CONFIG]) && (
                <Route path="auth">
                    <Route
                        index
                        element={
                            <Suspense fallback={<Pending />}>
                                <AuthProjects />
                            </Suspense>
                        }
                    />
                    <Route
                        path=":project"
                        element={
                            <Suspense fallback={<Pending />}>
                                <AuthProject />
                            </Suspense>
                        }
                    >
                        <Route index element={<Navigate replace to="config" />} />
                        <Route path="config" element={<AuthConfig />} />
                        <Route path="entitlements" element={<AuthEntitlements />} />
                        <Route path="user">
                            <Route index element={<AuthUser />} />
                            <Route path=":user" element={<AuthUserContainer />}>
                                <Route index element={<Navigate replace to="parameters" />} />
                                <Route path="parameters" element={<AuthUserParameters />} />
                                <Route path="entitlements" element={<AuthUserEntitlements />} />
                                <Route path="grants" element={<AuthUserGrants />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            )}
            {getListPermission(objectPermissions[ObjectEnum.NODE]) &&
                getListPermission(objectPermissions[ObjectEnum.GROUP]) && (
                    <Route path="itassets">
                        <Route
                            index
                            element={
                                <Suspense fallback={<Pending />}>
                                    <ItAssets />
                                </Suspense>
                            }
                        />
                    </Route>
                )}
            <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
    );
};

const PagesWithProject = () => {
    const { project } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const projects = useSelector((state: RootState) => state.projects);
    const token = useSelector((state: RootState) => state.token);
    const activeProject = useSelector((state: RootState) => state.activeProject);

    useEffect(() => {
        if (token.result === "REQUEST_LIMIT_EXCEED") {
            dispatch(resetToken());
            navigate("/500");
        }
    }, [dispatch, navigate, token]);

    useEffect(() => {
        if (project !== activeProject.publickey && !projects.length) {
            dispatch(getProjects());
        } else if (project && project !== activeProject.publickey && projects.length !== 0) {
            const selectedProject = projects.find((projectItem: ProjectType) => projectItem.publickey === project);
            if (selectedProject) {
                dispatch(getActiveProject(selectedProject));
            }
        }
    }, [projects, activeProject, dispatch, project]);

    if (project !== activeProject.publickey) {
        return <Pending />;
    }

    return (
        <Routes>
            <Route path="dashboard" element={<Dashboard />}>
                <Route index element={<Navigate replace to="general" />} />
                <Route path="general" element={<GeneralGraphs />} />
                <Route path="location" element={<GeoChart />} />
            </Route>
            <Route path="users">
                <Route
                    index
                    element={
                        <Suspense fallback={<Pending />}>
                            <Users />
                        </Suspense>
                    }
                />
                <Route path=":user" element={<User />}>
                    <Route index element={<Navigate replace to="parameters" />} />
                    <Route path="sessions" element={<Sessions />} />
                    <Route path="purchases" element={<Purchases />} />
                    <Route path="devices" element={<Devices />} />
                    <Route path="parameters" element={<Parameters />} />
                </Route>
            </Route>
            <Route path="active_sessions" element={<ActiveSessions />} />
            <Route path="network" element={<Network />}>
                <Route index element={<Navigate replace to="countries" />} />
                <Route path="countries" element={<Countries />} />
                <Route path="location" element={<Locations />} />
                <Route path="pools">
                    <Route index element={<Pools />} />
                    <Route path=":pool" element={<Pool />}>
                        <Route index element={<Navigate replace to="optimal" />} />
                        <Route path="optimal" element={<OptimalLocation />} />
                        <Route path="rules" element={<PoolLocationsProject />} />
                    </Route>
                </Route>
            </Route>
            <Route
                path="settings"
                element={
                    <Suspense fallback={<Pending />}>
                        <Settings />
                    </Suspense>
                }
            >
                <Route index element={<Navigate replace to="general" />} />
                <Route path="general" element={<GeneralSettings />} />
                <Route path="fireshield" element={<Fireshield />} />
                <Route path="payments" element={<Payments />} />
                <Route path="members" element={<Members />} />
                <Route path="authentifications" element={<Authentifications />} />
                <Route path="vpn" element={<VPN />}>
                    <Route index element={<Navigate replace to="overall" />} />
                    <Route path="overall" element={<GeneralVPN />} />
                    <Route path="bypass" element={<Bypass />} />
                    <Route path="networks" element={<ClientNetworks />} />
                    <Route path="sni" element={<SNI />} />
                    <Route path="pool" element={<PoolSelectionRules />} />
                    <Route path="offload" element={<Offload />} />
                </Route>
            </Route>
            <Route path="export" element={<Export />} />
            <Route path="event" element={<Log />} />
            <Route path="license_keys" element={<LicenseKeys />} />
        </Routes>
    );
};

const Portal = () => {
    const [authorizationStatus] = useContext(AuthContext);
    const account = useSelector((state: RootState) => state.account);
    const objectPermissions = useSelector((state: RootState) => state.objectPermissions);
    const dispatch = useDispatch();

    const checkGlobalPermissions = useCallback(async () => {
        const permissionsScreenRequest = listOfPages.map((screenName) =>
            getObjectByKindRequest({
                kind: ObjectEnum.PERMISSION,
                name: screenName,
                namespace: GENERAL_NAMESPACE,
            }),
        );
        const permissionsRequest = await Promise.allSettled<AxiosResponse<IObjectResponse>>(permissionsScreenRequest);
        const permissions = {} as Record<ObjectEnum, PermissionEnum[]>;
        permissionsRequest
            .filter(({ status }) => status === "fulfilled")
            .filter(({ value }: any) => value.data && value.data.result === ResultResponse.GET_RESULT_OK)
            .forEach(({ value }: any) => {
                const {
                    data: {
                        object: { metadata, data },
                    },
                } = value as AxiosResponse<IObjectResponse>;
                const { ops } = base64ToJson(data);
                permissions[metadata.name as ObjectEnum] = ops;
            });

        dispatch(setObjectPermissions(permissions));
    }, [dispatch]);

    useEffect(() => {
        if (account.authorized) {
            checkGlobalPermissions().then();
            dispatch(getProjects());
        }
    }, [account.authorized, checkGlobalPermissions]);

    if (EAuthorizationStatus.Unauthorized === authorizationStatus) {
        return <Navigate replace to="/" />;
    }

    if (!account.authorized || objectPermissions[ObjectEnum.UNKNOWN]) {
        return <Pending />;
    }

    return (
        <ConfigTheme>
            <Routes>
                <Route path="404" element={<NotFound />} />
                <Route path="500" element={<Exception />} />
                <Route path="error" element={<Exception />} />
                <Route path="profile" element={<Profile />}>
                    <Route index element={<Navigate replace to="main" />} />
                    <Route path="main" element={<Main />} />
                    <Route path="security" element={<AccountSecurity />} />
                </Route>
                <Route element={<MainTab />}>
                    <Route path="new-routing">
                        <Route
                            index
                            element={
                                <Suspense fallback={<Pending />}>
                                    <ProjectList />
                                </Suspense>
                            }
                        />
                    </Route>
                    <Route path="projects">
                        <Route
                            index
                            element={
                                <Suspense fallback={<Pending />}>
                                    <VPNList />
                                </Suspense>
                            }
                        />
                        <Route element={<PageWithMenu />}>
                            <Route path=":project">
                                <Route index element={<Navigate replace to="dashboard" />} />
                                <Route path="*" element={<PagesWithProject />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path="*" element={<PageWithNamespace />} />
                </Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
        </ConfigTheme>
    );
};

export default Portal;
