interface IModalProps {
    isOpen: boolean;
    isNegative?: boolean;
    title?: string;
    confirm: {
        label: string;
        onConfirm: () => void;
    };
    onClose: () => void;
    disabled?: boolean;
    children: any;
    typeButton?: "button" | "submit" | "reset";
    nameForm?: string;
    withoutButtons?: boolean;
}

type ModalType<T> = {
    title: string;
    isOpen: boolean;
    closeModal: () => void;
    action: (item: T) => void;
    item: T;
    isDisabled?: boolean;
};

interface IModalButtonProps {
    button: any;
    confirm?: {
        label: string;
        onConfirm: () => void;
    };
    disabled?: boolean;
    typeButton?: "button" | "submit" | "reset";
    nameForm?: string;
}

type IModalContentProps = {
    ref?: any;
    $isOpen: boolean;
};

type TModal<T> = {
    editModal: boolean;
    deleteModal: boolean;
    createModal: boolean;
    item: T;
};

type TAction<T> = {
    type: EModals;
    payload: T;
};

enum EModals {
    EditModal,
    DeleteModal,
    CreateModal,
    CloseModals,
}

interface IPropsModal<T> {
    title: string;
    action: (item: T) => void;
    isOpen: boolean;
    item: T;
    close: () => void;
    buttonName: string;
}

export { EModals };

export type { IPropsModal, TAction, TModal, IModalContentProps, IModalButtonProps, IModalProps, ModalType };
