import React from "react";

import { TableDataType } from "@components/Table/types";
import { getDateString } from "@common/methods";
import { TargetIcon } from "@components/TargetIcon";
import iconDelete from "@common/img/svg/delete-icon.svg";
import iconPlay from "@common/img/svg/play-icon.svg";
import * as table from "@components/Table/style.scss";
import { getPortalTokenFromState } from "@common/getPortalTokenFromState";
import { IPurchase } from "@screens/Users/User/Content/Purchases/types";

const headings = [
    {
        isSort: true,
        heading: window.locales.transactionId,
    },
    {
        isSort: true,
        heading: window.locales.productId,
    },
    {
        isSort: true,
        heading: window.locales.type,
    },
    {
        isSort: true,
        heading: window.locales.transactionTime,
    },
    {
        isSort: true,
        heading: window.locales.expirationTime,
    },
    {
        isSort: true,
        heading: window.locales.active,
    },
    {
        isSort: true,
        heading: window.locales.subscription,
    },
    {
        isSort: false,
        heading: window.locales.rawData,
    },
];

const getPurchaseType = (type: string) => {
    switch (type) {
        case "apple_primary":
            return "iTunes";
        case "google":
            return "Google";
        case "elite":
            return "Elite";
        default:
            return type;
    }
};

const accumulatePurchases = (
    purchases: IPurchase[],
    toggleDisable: (id: number) => void,
    toggleEnable: (id: number) => void,
    selectedUserId: string,
): TableDataType[] =>
    purchases.map((purchase) => {
        const { id = 0, type = "", check_time = 0, purchase_data = {}, active_purchase = "" } = purchase;
        const { receipt = null } = purchase_data;
        const parseReceipt = receipt && receipt?.in_app ? receipt?.in_app : null;
        const updatePurchaseData = parseReceipt || purchase_data;
        const {
            orderId = null,
            transaction_id = null,
            autoRenewing = null,
            expiryTimeMillis = null,
            productId = null,
            is_trial_period = null,
            expires_date_ms = null,
        } = updatePurchaseData;
        const updateID = orderId || transaction_id;
        const time = getDateString(check_time);
        const data = getDateString(expiryTimeMillis || expires_date_ms) || "";
        const access_token = getPortalTokenFromState();

        return {
            id: updateID || id,
            productId: productId || "",
            type: getPurchaseType(type),
            transactionTime: time ? `${time.date} ${time.time}` : "",
            expirationTime: data ? `${data.date} ${data.time}` : "",
            active: active_purchase ? "Yes" : "No",
            subscription: autoRenewing ? "Yes" : "No",
            trial: is_trial_period === "true" ? "Yes" : "No",
            rawData: {
                component: (
                    <a
                        href={`partner/subscribers/${selectedUserId}/purchase?access_token=${access_token}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        JSON
                    </a>
                ),
            },
            buttons: {
                component: (
                    <div className={table.buttonsColumn}>
                        {active_purchase ? (
                            <TargetIcon onClick={() => toggleDisable(id)}>
                                <img src={iconDelete} alt="delete" />
                            </TargetIcon>
                        ) : (
                            <TargetIcon onClick={() => toggleEnable(id)}>
                                <img src={iconPlay} alt="play" />
                            </TargetIcon>
                        )}
                    </div>
                ),
            },
        };
    });

export { accumulatePurchases, headings };
