import React, { FC } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import iconCopy from "@common/img/svg/copy-icon.svg";
import { TableBodyProps, TableDataType, TableObjectProps } from "@components/Table/types";
import { TargetIcon } from "@components/TargetIcon";
import * as table from "@components/Table/style.scss";
import { Tooltip } from "@components/Tooltip";
import * as tooltipStyle from "@components/Tooltip/style.scss";
import { DIRECTIONS } from "@components/Tooltip/types";
import { TD_TEXT_LIMIT } from "@components/Table/TableBody/constants";
import { successInfo } from "@state/success";

const tabTD = (item: any, index: number, isReduce: boolean) => {
    const typeR = typeof item;

    switch (typeR) {
        case "object":
            return handleObject(item, index);
        case "function":
            return null;
        case "string":
            return handleString(item, item, index, isReduce);
        case "number":
            return (
                <td className={`${table.td} ${index !== 0 && table.number}`} key={index}>
                    <span>{item}</span>
                </td>
            );
        default:
            return (
                <td className={table.td} key={index}>
                    <h5>{item}</h5>
                </td>
            );
    }
};

const getCopyButton = (item: string) => (
    <div className={table.tdButton}>
        <CopyToClipboard text={item}>
            <TargetIcon onClick={() => successInfo.setSuccessInfoWithText(window.locales.copiedSuccess)}>
                <img src={iconCopy} alt="Copy" />
            </TargetIcon>
        </CopyToClipboard>
    </div>
);

const getTdWithTooltip = (title: string, content: string, key: number) => {
    if (!title) return <div />;
    const copyButton = getCopyButton(content);
    const tooltip = <Tooltip direction={DIRECTIONS.BOTTOM} text={content} />;

    return (
        <td className={`${table.td} ${tooltipStyle.tooltipWrapper}`} key={key}>
            <h5>{title}</h5>
            {copyButton}
            {tooltip}
        </td>
    );
};

const handleString = (title: string, content: string, key: number, isReduce: boolean) => {
    if (isReduce && title.length >= TD_TEXT_LIMIT) {
        return getTdWithTooltip(title, content, key);
    }

    return (
        <td className={`${table.td} ${tooltipStyle.tooltipWrapper}`} key={key}>
            <h5>{title}</h5>
        </td>
    );
};

const handleObject = (item: TableObjectProps, key: number) => {
    if (item && item.tooltip) {
        const { title, content } = item.tooltip;

        return getTdWithTooltip(title, content, key);
    }

    return (
        <td className={table.td} key={key}>
            <>{item.component}</>
        </td>
    );
};

const content = (item: TableDataType, isReduce: boolean) =>
    Object.entries(item).map((val, index) => tabTD(val[1], index, isReduce));

const TableBody: FC<TableBodyProps> = ({ tableData, isReduce }) => (
    <tbody className={table.tBody}>
        {tableData.map((item, index) => (
            <tr
                key={index}
                className={table.tr}
                onClick={() => {
                    if (typeof item.event === "function") item.event();
                }}
            >
                {content(item, isReduce)}
            </tr>
        ))}
    </tbody>
);

export { TableBody };
