import React, { ReactElement, Suspense } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import * as commonStyle from "@common/style/style.scss";
import Header from "@components/Header";
import { ObjectEnum, PermissionEnum } from "@screens/Objects/Object/Object.constants";
import RootState from "@state/interfaces";
import * as style from "@components/MainTab/MainTab.style.scss";
import { If } from "@components/If";

function MainTab(): ReactElement {
    const objectPermissions = useSelector((state: RootState) => state.objectPermissions);
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const { namespace } = objectPermissions;

    return (
        <>
            <Header />
            <If condition={!activeProject.publickey}>
                <div className={style.sectionWrapper}>
                    <Suspense fallback={<h2>Loading...</h2>}>
                        <div className={commonStyle.tabsContainer}>
                            <NavLink
                                to="/projects"
                                className={({ isActive }) =>
                                    `${commonStyle.link} ${isActive && commonStyle.activeLink}`
                                }
                            >
                                {window.locales.projects}
                            </NavLink>
                            {namespace?.includes(PermissionEnum.GET) && <NameSpaceTabs />}
                        </div>
                    </Suspense>
                </div>
            </If>
            <Outlet />
        </>
    );
}

const getListPermission = (permissions: string[]) => permissions && permissions.includes(PermissionEnum.LIST);

const NameSpaceTabs = (): ReactElement => {
    const objectPermissions = useSelector((state: RootState) => state.objectPermissions);

    return (
        <>
            {getListPermission(objectPermissions[ObjectEnum.FEATURE]) && (
                <NavLink
                    to="/features"
                    className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                >
                    {window.locales.features}
                </NavLink>
            )}
            {getListPermission(objectPermissions[ObjectEnum.DISCOVERY_POOL_CONFIG]) && (
                <NavLink
                    to="/pools"
                    className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                >
                    {window.locales.pools}
                </NavLink>
            )}
            <NavLink
                to="/namespaces"
                className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
            >
                {window.locales.namespaces}
            </NavLink>
            {getListPermission(objectPermissions[ObjectEnum.PROJECT_AUTH_CONFIG]) && (
                <NavLink
                    to="/auth"
                    className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                >
                    {window.locales.auth}
                </NavLink>
            )}
            {getListPermission(objectPermissions[ObjectEnum.ROLE]) && (
                <NavLink
                    to="/access"
                    className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                >
                    {window.locales.access}
                </NavLink>
            )}
            {getListPermission(objectPermissions[ObjectEnum.NODE]) &&
                getListPermission(objectPermissions[ObjectEnum.GROUP]) && (
                    <NavLink
                        to="/itassets"
                        className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                    >
                        {window.locales.itAssets}
                    </NavLink>
                )}
            {getListPermission(objectPermissions[ObjectEnum.SESSION]) && (
                <NavLink
                    to="/sessions"
                    className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                >
                    {window.locales.sessions}
                </NavLink>
            )}
            {getListPermission(objectPermissions[ObjectEnum.CLIENT_CONFIG_OVERRIDE]) && (
                <NavLink
                    to="/overrides"
                    className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                >
                    {window.locales.overrides}
                </NavLink>
            )}
            {getListPermission(objectPermissions[ObjectEnum.CREDENTIALS]) && (
                <NavLink
                    to="/credentials"
                    className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                >
                    {window.locales.vpnCredentials}
                </NavLink>
            )}
        </>
    );
};

export default MainTab;
