import React from "react";

import { ObjectType } from "@screens/Objects/Objects.types";
import * as table from "@components/Table/style.scss";
import { If } from "@components/If";
import { TargetIcon } from "@components/TargetIcon";
import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";
import base64ToJson from "@common/methods/base64toJSON";
import { PermissionEnum } from "@screens/Objects/Object/Object.constants";
import { getAge } from "@common/getAge";
import { ClientConfigOverride, OverrideObjectType, OverridesTableType } from "@screens/Overrides/Overrides.types";

const overrideHeader = [
    {
        isSort: true,
        heading: window.locales.name,
    },
    {
        isSort: true,
        heading: window.locales.age,
    },
];

const accumulateOverrides = (
    objects: ObjectType[],
    showEditModal: (object: OverrideObjectType) => void,
    onDelete: (object: OverrideObjectType) => void,
    permissions: string[],
): OverridesTableType[] =>
    objects.map((object: ObjectType) => {
        const {
            metadata: { name, updateTime = "" },
            data,
        } = object;

        const dataObject = base64ToJson(data) as ClientConfigOverride;

        const userCanDelete = permissions.includes(PermissionEnum.DELETE);
        const userCanEdit = permissions.includes(PermissionEnum.PUT);

        const row: OverridesTableType = {
            name,
            age: {
                sortColumn: Number(updateTime),
                component: getAge(updateTime),
            },
            buttons: {
                component: (
                    <div className={table.buttonsColumn}>
                        <If condition={userCanEdit}>
                            <TargetIcon
                                onClick={() =>
                                    showEditModal({
                                        metadata: object.metadata,
                                        data: dataObject,
                                    })
                                }
                            >
                                <img src={iconPen} alt="img" />
                            </TargetIcon>
                        </If>
                        <If condition={userCanDelete}>
                            <TargetIcon
                                onClick={() =>
                                    onDelete({
                                        metadata: object.metadata,
                                        data: dataObject,
                                    })
                                }
                            >
                                <img src={iconTrash} alt="img" />
                            </TargetIcon>
                        </If>
                    </div>
                ),
            },
        };

        return row;
    });

export { overrideHeader, accumulateOverrides };
