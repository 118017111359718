import axios from "axios";

import Network from "@common/Network";
import { getPortalTokenFromState } from "@common/getPortalTokenFromState";
import { getRequestByToken, putUrlencodedRequestByToken } from "@common/requests/requestsAPI";
import { successInfo } from "@state/success";
import { ProjectType } from "@screens/VPN/VPN.types";

type UserType = {
    name: string;
    license_id: string;
    extref: string;
    given_name: string;
    country: string;
};

axios.defaults.baseURL = `/api/${process.env.CURRENT_API}/`;
const changeUserCondition = (subscriberId: string, condition: string) => {
    successInfo.setSuccessInfo();
    return putUrlencodedRequestByToken(`partner/subscribers/${subscriberId}`, {
        condition,
    });
};

const signIn = (login: string, password: string) =>
    axios.post(
        "partner/login",
        {
            login,
            password,
        },
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
    );

const getUsers = async (searchParams = "", activeProject: ProjectType, page = 0) => {
    if (activeProject.project_type === "private_vpn") {
        return loadPrivateUsers(searchParams, page);
    }

    return loadRegularUsers(searchParams, page);
};

const loadPrivateUsers = async (searchParams = "", page = 0) => {
    try {
        const {
            data: { users, result, user },
        } = await getRequestByToken<{
            data: {
                users: [];
                user: Record<string, string>;
                result: string;
            };
        }>(`partner/users${searchParams}`, {
            page,
        });

        if (result !== "OK") {
            return [];
        }

        return users || [user];
    } catch (e) {
        return [];
    }
};

const loadRegularUsers = async (searchParams: string, page = 0) => {
    try {
        const requestUrl = `partner/subscribers${searchParams}`;

        const {
            data: { subscribers, result, subscriber },
        } = await getRequestByToken<{
            data: {
                subscribers: [];
                subscriber: Record<string, string>;
                result: string;
            };
        }>(requestUrl, {
            page,
        });

        if (result !== "OK") {
            return [];
        }

        return subscribers || [subscriber];
    } catch (e) {
        return [];
    }
};
const editUser = async (uid: string, data: UserType) => {
    const response = await putUrlencodedRequestByToken(`partner/subscribers/${uid}`, {
        ...data,
        user_id: uid,
    });
    successInfo.setSuccessInfo();

    return response;
};

const editUserData = (id: string) => {
    const access_token = getPortalTokenFromState();
    const request = `partner/subscribers/${id}/userdata`;
    const params = {
        delete: true,
        export: true,
    };

    return Network.post(request, params, null, access_token);
};

export { signIn, getUsers, editUser, editUserData, loadRegularUsers, changeUserCondition };
