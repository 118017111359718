import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import { IAuthMethod } from "@screens/Settings/Authentifications/types";
import Table from "@components/Table";
import { accumulateAuthMethods, headings } from "@screens/Settings/Authentifications/utils";
import RootState from "@state/interfaces";
import { TableDataType } from "@components/Table/types";
import {
    addAuthentification,
    deleteAuthentifications,
    editAuthentification,
    getAuthentifications,
} from "@services/Authentification";
import { EModals } from "@components/Modal/types";
import * as poolStyle from "@screens/Network/Pool/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import { If } from "@components/If";
import { AuthMethodModal } from "@screens/Settings/Authentifications/AuthModal";
import { Modal } from "@components/Modal";
import { successInfo } from "@state/success";
import { IconPlus } from "@common/icons";

import { Permissions } from "../../../enums";

const initAuthMethod: IAuthMethod = {
    auth_method: "",
    auth_method_id: "",
    auth_settings: {},
    show: false,
};

const initialState = initialStateModal(initAuthMethod);
const reducerAuthMethod = reducerModal(initialState);

const Authentifications = () => {
    const [modals, dispatchModal] = useReducer(reducerAuthMethod, initialState);
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const permissions = useSelector((state: RootState) => state.permissions);
    const navigate = useNavigate();
    const [body, setBody] = useState<TableDataType[]>([]);
    const isEdit = useMemo(() => permissions.includes(Permissions.AuthUpdate), [permissions]);
    const [data, setData] = useState<IAuthMethod[]>([]);

    useEffect(() => {
        if (!permissions.includes(Permissions.AuthRead)) {
            navigate("/");
        }
    }, [navigate, permissions]);

    const showEditModal = (authMethod: IAuthMethod) => {
        dispatchModal({
            type: EModals.EditModal,
            payload: authMethod,
        });
    };

    const showDeleteModal = (authMethod: IAuthMethod) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: authMethod,
        });
    };

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: initAuthMethod,
        });
    };

    useEffect(() => {
        const content = accumulateAuthMethods(data, isEdit, showEditModal, showDeleteModal, handleVisibility);
        setBody(content);
    }, [data, isEdit]);

    const handleVisibility = (auth_method_id: number) => {
        setData((prevState) => {
            const index = prevState.findIndex((method) => method.auth_method_id === auth_method_id);
            prevState[index].show = !prevState[index].show;
            return [...prevState];
        });
    };

    const createAuthMethod = async (authMethod: IAuthMethod) => {
        await addAuthentification(activeProject.publickey, authMethod.auth_method, authMethod.auth_settings);
        successInfo.setSuccessInfo();
        getData();
    };

    const editAuthMethod = async (authMethod: IAuthMethod) => {
        if (
            modals.item.auth_method === authMethod.auth_method &&
            modals.item.auth_settings === authMethod.auth_settings
        )
            return;

        await editAuthentification(
            activeProject.publickey,
            authMethod.auth_method,
            authMethod.auth_settings,
            authMethod.auth_method_id,
        );

        getData();
        successInfo.setSuccessInfo();
    };

    const deleteAuthMethod = async () => {
        await deleteAuthentifications(activeProject.publickey, modals.item.auth_method, modals.item.auth_method_id);

        getData();
        successInfo.setSuccessInfo();

        closeModal();
    };

    const getData = useCallback(() => {
        getAuthentifications(activeProject.publickey).then((result) => {
            setData(result);
        });
    }, [activeProject]);

    useEffect(() => {
        getData();
    }, [activeProject, getData, isEdit, permissions]);

    return (
        <>
            <Table
                tableData={body}
                headings={headings}
                title={window.locales.authMethod}
                emptyMessage={window.locales.noAuthentifications}
            >
                <If condition={isEdit}>
                    <div className={poolStyle.extraButtons}>
                        <button
                            className={buttonStyle.buttonAdd}
                            type="submit"
                            onClick={() =>
                                dispatchModal({
                                    type: EModals.CreateModal,
                                    payload: initAuthMethod,
                                })
                            }
                        >
                            <IconPlus theme="blue" />
                            {window.locales.add}
                        </button>
                    </div>
                </If>
            </Table>

            {/* Modal create auth method */}
            <AuthMethodModal
                title={window.locales.addAuthMethod}
                action={createAuthMethod}
                isOpen={modals.createModal}
                item={initAuthMethod}
                close={closeModal}
                buttonName={window.locales.confirm}
            />

            {/* Modal edit auth method */}
            <AuthMethodModal
                title={window.locales.editAuthMethod}
                action={editAuthMethod}
                isOpen={modals.editModal}
                item={modals.item}
                close={closeModal}
                buttonName={window.locales.confirm}
            />

            {/* Modal delete auth method */}
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                title={window.locales.deleteRule}
                onClose={closeModal}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteAuthMethod,
                }}
            >
                {`Do you really want to delete auth method by id ${modals.item.auth_method_id}?`}
            </Modal>
        </>
    );
};

export default Authentifications;
