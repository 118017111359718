import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import React, { useEffect } from "react";
import * as buttonStyle from "@components/Button/style.scss";

type MFAType = {
    setPhoneNumberMethod: () => void;
    setTOTPMethod: () => void;
};

function MFA({ setPhoneNumberMethod, setTOTPMethod }: MFAType) {
    const [email, setEmail] = React.useState<string>("");

    useEffect(() => {
        const currentUser = window.fb.default.auth().currentUser;
        setEmail(currentUser.email);
    }, []);

    return (
        <div className={authorizationStyle.formCenter}>
            <div className={authorizationStyle.signFormContainer}>
                <div className={authorizationStyle.titleContainer}>
                    <div className={authorizationStyle.title}>Verification</div>
                    <div className={authorizationStyle.text}>
                        You need to choose a method of two-factor authentication for&nbsp;<span>{email}</span>
                    </div>
                </div>
                <div className={authorizationStyle.mfaButtons}>
                    <button className={buttonStyle.buttonSubmit} onClick={setTOTPMethod}>
                        {window.locales.totp}
                    </button>
                    <button className={buttonStyle.buttonSubmit} onClick={setPhoneNumberMethod}>
                        {window.locales.phoneNumber}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MFA;
