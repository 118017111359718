import React, { useCallback, useRef, useState } from "react";
import { Field, Formik } from "formik";
import { useSelector } from "react-redux";
import { FormikErrors } from "formik/dist/types";

import { resetPassword, signIn } from "@services/firebase";
import { RootState } from "@state/index";
import * as twoFactoryAuthStyle from "@screens/Profile/AccountSecurity/TwoFactoryAuth/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import * as profileStyle from "@screens/Profile/style.scss";
import { TextField } from "@components/FormikFields";
import { ModalCode } from "@screens/Profile/AccountSecurity/ChangePassword/ModalCode";
import { passwordRegex } from "@common/checkValidPWD";
import { PhoneMultiFactorGenerator, TotpMultiFactorGenerator } from "firebase/auth";
import { successInfo } from "@state/success";

export const ChangePassword = () => {
    const { name } = useSelector((state: RootState) => state.account);
    const [isRequesting, setIsRequesting] = useState(false);
    const refResolver = useRef(null);
    const verificationId = useRef("");
    const [codeModal, setCodeModal] = useState(false);
    const recaptchaVerifier = useRef(null);
    const captchaRef = useRef(null);
    const [isErrorCode, setErrorCode] = useState(false);

    const goToSignIn = async (
        email: string,
        password: string,
        setErrors: (errors: FormikErrors<any>) => void,
        resetForm: any,
    ) => {
        signIn(email.toLowerCase(), password)
            .then(async () => {
                await window.fb.default.auth().currentUser.updatePassword(password);
                resetForm({});
            })
            .catch(async (error) => {
                const { code, resolver } = error;

                if (code === "auth/multi-factor-auth-required") {
                    const [multiFactorHint] = resolver.hints;
                    const { factorId } = multiFactorHint;

                    if (!resolver) {
                        return;
                    }

                    if (PhoneMultiFactorGenerator.FACTOR_ID === factorId) {
                        recaptchaVerifier.current = new window.fb.default.auth.RecaptchaVerifier(captchaRef.current, {
                            size: "invisible",
                        });
                        const phoneAuthProvider = new window.fb.default.auth.PhoneAuthProvider();
                        verificationId.current = await phoneAuthProvider.verifyPhoneNumber(
                            {
                                multiFactorHint,
                                session: resolver.session,
                            },
                            recaptchaVerifier.current,
                        );
                    }

                    setCodeModal(true);
                    refResolver.current = resolver;
                } else {
                    setErrors({
                        current_password: window.locales.invalidPwd,
                    });
                }
            });
    };

    const updatePassword = async (code: string, password: string, setErrors: any, resetForm: any) => {
        try {
            if (!refResolver.current) return;
            // @ts-ignore
            const [multiFactorHint] = refResolver.current.hints;
            const { factorId, uid } = multiFactorHint;
            if (TotpMultiFactorGenerator.FACTOR_ID === factorId) {
                const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(uid, code);
                // @ts-ignore
                await refResolver.current.resolveSignIn(multiFactorAssertion);
            } else if (PhoneMultiFactorGenerator.FACTOR_ID === factorId) {
                const cred = window.fb.default.auth.PhoneAuthProvider.credential(verificationId.current, code);
                const multiFactorAssertion = window.fb.default.auth.PhoneMultiFactorGenerator.assertion(cred);
                // @ts-ignore
                await refResolver.current.resolveSignIn(multiFactorAssertion);
            } else {
                return;
            }
            setCodeModal(false);
        } catch {
            setErrorCode(true);
            return;
        }

        try {
            await window.fb.default.auth().currentUser.updatePassword(password);
            successInfo.setSuccessInfoWithText(window.locales.passwordChanged);
            resetForm({});
        } catch {
            setErrors({
                new_password: " ",
                confirm_password: " ",
            });
        }
    };

    const sendResetPassword = useCallback(async () => {
        if (name) {
            await resetPassword(name);
            successInfo.setSuccessInfoWithText(window.locales.descriptionCheckMailbox);
        }
    }, [name]);

    return (
        <>
            <Formik
                initialValues={{
                    current_password: "",
                    new_password: "",
                    confirm_password: "",
                }}
                validate={(values) => {
                    const errors: Record<string, string> = {};
                    const { current_password, new_password, confirm_password } = values;
                    const {
                        invalidEmptyPassword,
                        invalidEmptyCurrentPassword,
                        invalidEmailMatchPassword,
                        invalidPasswordMatch,
                    } = window.locales;
                    if (current_password.length === 0) errors.current_password = invalidEmptyCurrentPassword;
                    if (!new_password) errors.new_password = invalidEmptyPassword;
                    if (new_password === name) errors.new_password = invalidEmailMatchPassword;
                    if (!confirm_password) errors.confirm_password = invalidEmptyPassword;
                    if (confirm_password !== new_password) errors.confirm_password = invalidPasswordMatch;
                    if (!passwordRegex.test(new_password)) {
                        errors.new_password = window.locales.weakPassword;
                    }
                    return errors;
                }}
                onSubmit={async (values, { setErrors, resetForm }) => {
                    setIsRequesting(true);
                    const { current_password } = values;
                    await goToSignIn(name, current_password, setErrors, resetForm);
                    setIsRequesting(false);
                }}
            >
                {({ values, handleSubmit, dirty, isValid, setErrors, resetForm }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalCode
                            isError={isErrorCode}
                            closeModal={() => setCodeModal(false)}
                            isOpen={codeModal}
                            applyForm={async (code: string) => {
                                setErrorCode(false);
                                await updatePassword(code, values.new_password, setErrors, resetForm);
                            }}
                        />
                        <div className={profileStyle.titleSection}>
                            {window.locales.changePassword}
                            <div className={twoFactoryAuthStyle.resetButton} onClick={sendResetPassword}>
                                {window.locales.reset}
                            </div>
                        </div>
                        <div className={profileStyle.fieldsContainer}>
                            <Field
                                component={TextField}
                                type="password"
                                name="current_password"
                                autoComplete="current-password"
                                placeholder="Current password"
                            />
                            <Field
                                component={TextField}
                                type="password"
                                name="new_password"
                                autoComplete="new-password"
                                placeholder="New password"
                            />
                            <Field
                                component={TextField}
                                type="password"
                                autoComplete="new-password"
                                name="confirm_password"
                                placeholder="Repeat new password"
                            />
                        </div>
                        <div className={profileStyle.buttonContainer}>
                            <button
                                className={buttonStyle.buttonSubmit}
                                type="submit"
                                disabled={!(isValid && dirty) || isRequesting}
                            >
                                {window.locales.save}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
            <div ref={captchaRef} />
        </>
    );
};
