import React from "react";

import { IDataItemProps, ISessions } from "@screens/ActiveSessions/types";
import { TableDataType } from "@components/Table/types";
import { byteConvert, getCountryName, getDateString } from "@common/methods";
import * as table from "@components/Table/style.scss";

const headings = [
    {
        isSort: true,
        heading: window.locales.uid,
    },
    {
        isSort: true,
        heading: window.locales.did,
    },
    {
        isSort: true,
        heading: window.locales.server,
    },
    {
        isSort: false,
        heading: window.locales.location,
    },
    {
        isSort: true,
        heading: window.locales.startTime,
    },
    {
        isSort: true,
        heading: window.locales.download,
    },
    {
        isSort: true,
        heading: window.locales.upload,
    },
];

const DataItem: React.FC<IDataItemProps> = ({ date, time }) => (
    <>
        <div className={table.dateItem}>{date}</div>
        <div className={table.time}>{time}</div>
    </>
);

const accumulateSessions = (sessions: ISessions[], openUserPage: (id: string) => void): TableDataType[] =>
    sessions.map((session) => {
        const { user_id, tx, rx, server, device_id, server_country, start_time } = session;
        const upload = byteConvert(tx);
        const download = byteConvert(rx);
        const date = getDateString(start_time) || {
            date: "",
            time: "",
        };
        return {
            user_id,
            device_id: {
                tooltip: {
                    title: device_id,
                    content: device_id,
                },
            },
            server,
            location: getCountryName(server_country),
            start_time: {
                sortColumn: start_time,
                component: <DataItem {...date} />,
            },
            download: (download && `${download.size} ${download.type}`) || "",
            upload: (upload && `${upload.size} ${upload.type}`) || "",
            event: () => {
                openUserPage(user_id);
            },
        };
    });

export { accumulateSessions, headings };
