import { TableDataType } from "@components/Table/types";
import { byteConvert, getDateString } from "@common/methods";
import { IParams, ISession } from "@screens/Users/User/Content/Sessions/types";

const headings = [
    {
        isSort: false,
        heading: window.locales.did,
    },
    {
        isSort: false,
        heading: window.locales.server,
    },
    {
        isSort: true,
        heading: window.locales.startTime,
    },
    {
        isSort: true,
        heading: window.locales.endTime,
    },
    {
        isSort: false,
        heading: window.locales.internalIp,
    },
    {
        isSort: true,
        heading: window.locales.download,
    },
    {
        isSort: true,
        heading: window.locales.upload,
    },
];

const accumulateSessions = (sessions: ISession[] = []): TableDataType[] =>
    sessions.map((session) => {
        const { start_time, end_time, rx, tx, device_id, server, internal_address } = session;
        const params: IParams = {
            start: getDateString(start_time) || "",
            end: getDateString(end_time) || "",
            download: byteConvert(rx) || "",
            upload: byteConvert(tx) || "",
        };

        return {
            device_id: {
                tooltip: {
                    title: device_id,
                    content: device_id,
                },
            },
            server,
            start_time: typeof params.start === "object" ? `${params.start.date} ${params.start.time}` : "",
            end_time: typeof params.end === "object" ? `${params.end.date} ${params.end.time}` : "",
            internal_IP: internal_address,
            download: typeof params.download === "object" ? `${params.download.size} ${params.download.type}` : "",
            upload: typeof params.upload === "object" ? `${params.upload.size} ${params.upload.type}` : "",
        };
    });

export { accumulateSessions, headings };
