import React, { ReactElement } from "react";
import { Modal } from "@components/Modal";
import { Field, Formik } from "formik";
import { TextField } from "@components/FormikFields";
import { ModalType } from "@components/Modal/types";
import { PurchaseType } from "@screens/Users/User/Content/Purchases/types";

function PurchaseModal({ title, isOpen, closeModal, action, item }: ModalType<PurchaseType>): ReactElement {
    return (
        <Modal
            isOpen={isOpen}
            onClose={closeModal}
            title={title}
            confirm={{
                label: window.locales.confirm,
                onConfirm: () => {},
            }}
            /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
            typeButton="submit"
            nameForm="tokenForm"
            disabled={false}
        >
            <Formik
                initialValues={item}
                validate={({ type, token }) => {
                    const errors: Record<string, string> = {};
                    if (!type) errors.type = window.locales.invalidFieldEmpty;
                    if (!token) errors.token = window.locales.invalidFieldEmpty;
                    return errors;
                }}
                onSubmit={({ type, token }) => {
                    action({ type, token });
                    closeModal();
                }}
            >
                {({ handleSubmit }) => (
                    /* ! add id to match nameForm from the Modal props  */
                    <form id="tokenForm" onSubmit={handleSubmit}>
                        <section>
                            <Field
                                component={TextField}
                                placeholder="Type type"
                                type="text"
                                name="type"
                                disabled={item.type}
                            />
                        </section>
                        <section>
                            <Field
                                component={TextField}
                                placeholder="Type token"
                                type="text"
                                name="token"
                                disabled={item.token}
                            />
                        </section>
                    </form>
                )}
            </Formik>
        </Modal>
    );
}

export default PurchaseModal;
