import React, { ReactElement } from "react";
import { Outlet } from "react-router-dom";

import * as style from "@components/PageWithMenu/style.scss";
import SideBar from "@components/SideBar/SideBar";
import Header from "@components/Header";
import { useSelector } from "react-redux";
import RootState from "@state/interfaces";

function PageWithMenu(): ReactElement {
    const activeProject = useSelector((state: RootState) => state.activeProject);

    if (!activeProject.publickey) {
        return (
            <div className={style.contentContainer}>
                <Outlet />
            </div>
        );
    }

    return (
        <>
            <Header />
            <SideBar />
            <div className={style.contentContainer}>
                <Outlet />
            </div>
        </>
    );
}

export default PageWithMenu;
