import React from "react";

import { IRule } from "@screens/Settings/VPN/SNI/SNI.types";
import { TableDataType } from "@components/Table/types";
import { TargetIcon } from "@components/TargetIcon";
import * as table from "@components/Table/style.scss";
import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";

const accumulateRules = (
    rules: IRule[],
    editRule: (rule: IRule) => void,
    showDeleteModal: (rule: IRule) => void,
): TableDataType[] =>
    rules.map((rule) => {
        const {
            selector = {
                device_type: { in: [] },
                user_country: "",
                app_version: "",
                sdk_version: "",
            },
            description = "",
        } = rule;
        const { device_type = { in: [] }, user_country = "", app_version = "", sdk_version = "" } = selector;

        const dataDeviceType = Array.isArray(device_type?.in) ? device_type?.in.join(", ") : "";
        return {
            description,
            device_type: dataDeviceType || "",
            country: user_country,
            app_version,
            sdk_version,
            buttons: {
                component: (
                    <div className={table.buttonsColumn}>
                        <TargetIcon onClick={() => editRule(rule)}>
                            <img src={iconPen} alt="img" />
                        </TargetIcon>
                        <TargetIcon onClick={() => showDeleteModal(rule)}>
                            <img src={iconTrash} alt="img" />
                        </TargetIcon>
                    </div>
                ),
            },
        };
    });

const headings = [
    {
        isSort: false,
        heading: window.locales.description,
    },
    {
        isSort: false,
        heading: window.locales.device_type,
    },
    {
        isSort: false,
        heading: window.locales.country,
    },
    {
        isSort: false,
        heading: window.locales.app_version,
    },
    {
        isSort: false,
        heading: window.locales.sdk_version,
    },
    {
        isSort: false,
        heading: "",
    },
];

export { accumulateRules, headings };
