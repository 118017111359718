import React, { ReactElement } from "react";

import { TableDataType } from "@components/Table/types";
import { TargetIcon } from "@components/TargetIcon";
import * as table from "@components/Table/style.scss";
import iconPen from "@common/img/svg/pen-icon.svg";

import { IPermission, IPool, TRowPool, TypePool, SelectorType } from "./types";

const getTagsPermission = (item: any[]) =>
    item.map(
        (tag, index) =>
            tag[0] !== "subject" && (
                <div className={table.tableTagCountries} key={index}>
                    {" "}
                    {tag[0].replace("can_", "")}
                </div>
            ),
    );

const getPermissions = (permissions: IPermission[] = [], publickey: string) => {
    const filteredPermissions = permissions.map((permission) => Object.entries(permission).filter((item) => item[1]));

    return filteredPermissions
        .filter((item) => {
            const [subject] = item;
            const [, projectName] = subject;

            return projectName === "*" || projectName === publickey;
        })
        .map((item, index) => (
            <div className={table.tableTagContainer} key={index}>
                <div className={table.tableTagName}>{`${item[0][1] === "*" ? "everyone" : item[0][1]}: `}</div>
                {getTagsPermission(Array.isArray(item) ? item : [])}
            </div>
        ));
};

const selectorElement = (selector: SelectorType): ReactElement[] =>
    Object.entries(selector).map(([key, value], index) => (
        <div className={table.tableTagContainer} key={index}>
            <div className={table.tableTagName}>{key}: </div>
            {typeof value === "object" ? (
                Object.entries(value).map(([selectorKey, selectorValues], index) =>
                    Array.isArray(selectorValues) ? (
                        selectorValues.map((selectorValue) => (
                            <div key={index} className={table.tableTagCountries}>
                                {selectorKey}: {selectorValue}
                            </div>
                        ))
                    ) : (
                        <div key={index} className={table.tableTagCountries}>
                            Not recognized
                        </div>
                    ),
                )
            ) : (
                <div className={table.tableTagCountries}> {value} </div>
            )}
        </div>
    ));

const accumulatePermissionPools = (
    pools: IPool[] = [],
    publickey: string,
    updatePool: (itemPool: IPool) => void,
    openPoolPage: (name: string) => void,
): TableDataType[] =>
    pools.map((pool: IPool, index) => {
        const { name, permissions, description } = pool;
        const permission = permissions.find((permission) => permission.subject === publickey);
        const canWrite = permissions.filter(({ subject, can_write }) => subject === publickey && can_write).length > 0;

        const row: TRowPool = {
            type: permission?.can_list ? TypePool.Private : TypePool.Public,
            name,
            request_selector: {
                component: getPermissions(permissions, publickey),
            },
            selector: pool.selector ? { component: selectorElement(pool.selector) } : "",
            description,
            buttons: { component: <div /> },
            event: () => {
                openPoolPage(name);
            },
        };

        if (canWrite) {
            row.buttons = {
                component: (
                    <div className={table.buttonsColumn} key={index}>
                        <TargetIcon onClick={() => updatePool(pool)}>
                            <img src={iconPen} alt="img" />
                        </TargetIcon>
                    </div>
                ),
            };
        }

        return row;
    });

const poolHeadings = [
    {
        isSort: true,
        heading: "Type",
    },
    {
        isSort: true,
        heading: "Name",
    },
    {
        isSort: false,
        heading: "Permissions",
    },
    {
        isSort: false,
        heading: "Pool selector",
    },
    {
        isSort: false,
        heading: "Description",
    },
    {
        isSort: false,
        heading: "",
    },
];

export { accumulatePermissionPools, poolHeadings };
