import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { IconPlus } from "@common/icons";
import Table from "@components/Table";
import RootState from "@state/interfaces";
import { createLicenseKeyRequest, getLicenseKeysRequest, deleteLicenseKeyRequest } from "@services/LicenseKeys";
import { Modal } from "@components/Modal";
import { EModals, ILicenseKey } from "@screens/LicenseKeys/types";
import {
    getDataTableLicenseKeys,
    initialStateModalLicenseKey,
    initLicenseKey,
    licenseKeysHeadings,
    reducerKeys,
} from "@screens/LicenseKeys/utils";
import { ModalLicenseKey } from "@screens/LicenseKeys/ModalLicenseKey";
import { Search } from "@components/Search/Search";
import * as modal from "@components/Modal/style.scss";
import * as style from "@screens/LicenseKeys/style.scss";
import { TableDataType } from "@components/Table/types";
import { successInfo } from "@state/success";
import * as buttonStyle from "@components/Button/style.scss";

const regex = new RegExp(/^[A-Za-z\d._-]{0,250}$/g);

const LicenseKeys = () => {
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const [modals, dispatchModal] = useReducer(reducerKeys, initialStateModalLicenseKey);
    const [body, setBody] = useState<TableDataType[]>([]);
    const [search, setSearch] = useState("");
    const filteredData: any = useRef();

    useEffect(() => {
        const isValidValue = regex.test(search) && search !== "";
        search.toLowerCase().trim();
        if (search && isValidValue) {
            const filtered = filteredData.current.filter(({ token = "" }: ILicenseKey) => token.includes(search));
            const dataTable = getDataTableLicenseKeys(filtered, openModal);
            setBody(dataTable);
        }
    }, [search]);

    const getTable = useCallback(async () => {
        if (!activeProject.publickey) {
            return;
        }

        const { response } = await getLicenseKeysRequest();
        const { license_keys } = response;
        filteredData.current = license_keys;
        const dataTable = license_keys ? getDataTableLicenseKeys(license_keys, openModal) : [];
        setBody(dataTable);
    }, [activeProject.publickey]);

    useEffect(() => {
        getTable().then();
    }, [activeProject.publickey, getTable]);

    const closeModal = () =>
        dispatchModal({
            type: EModals.CloseModals,
            payload: null,
        });

    const openModal = (modal: EModals, member?: ILicenseKey) =>
        dispatchModal({
            type: modal,
            payload: member,
        });

    const handleLicenseKey = async (licenseKey: ILicenseKey) => {
        try {
            const { token, metadata, expire_time = "0" } = licenseKey;

            await createLicenseKeyRequest({
                token,
                metadata,
                expire_time,
            });
            await getTable();
            successInfo.setSuccessInfo();
            closeModal();
        } catch (e) {
            window.console.error(e);
        }
    };

    const deleteLicenseKey = async () => {
        try {
            const { licenseKey } = modals;
            await deleteLicenseKeyRequest(licenseKey.token);
            await getTable();
            closeModal();
        } catch (e) {
            window.console.error(e);
        }
    };

    const searchCallback = (value: string) => {
        setSearch(value);
    };

    return (
        <>
            <div className={style.header}>
                <h1>{window.locales.licenseKeys}</h1>
            </div>
            <Table
                title={window.locales.licenseKeys}
                tableData={body}
                headings={licenseKeysHeadings}
                emptyMessage={window.locales.noLicenseKeys}
            >
                <div className={style.buttonsMembers}>
                    <Search callback={searchCallback} />
                    <div className={buttonStyle.buttonAdd}>
                        <IconPlus
                            theme="blue"
                            label={window.locales.add}
                            onClick={() => openModal(EModals.CreateKey)}
                        />
                    </div>
                </div>
            </Table>

            {/* Modal deleting token */}
            <Modal
                isOpen={modals.deleteLicenseKeyModal}
                title={window.locales.deleteLicenseKey}
                isNegative
                onClose={closeModal}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteLicenseKey,
                }}
            >
                <div className={modal.modalText}>
                    {`License key ${modals.licenseKey?.token} is going to be deleted. Please confirm the operation.`}
                </div>
            </Modal>

            {/* Modal create license key */}
            <ModalLicenseKey
                licenseKey={initLicenseKey}
                title={window.locales.addKey}
                isOpen={modals.createLicenseKeyModal}
                handleLicenseKey={handleLicenseKey}
                closeModal={closeModal}
            />

            {/* Modal edit license key */}
            <ModalLicenseKey
                licenseKey={modals.licenseKey || initLicenseKey}
                title={window.locales.editKey}
                isOpen={modals.editLicenseKeyModal}
                handleLicenseKey={handleLicenseKey}
                closeModal={closeModal}
            />
        </>
    );
};

export default LicenseKeys;
