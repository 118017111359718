import { getRequestByFirebaseToken } from "@common/requests/requestsAPI";
import { ProjectType } from "@screens/VPN/VPN.types";

const responseGetProjects = async () => {
    const {
        data: { projects = [] },
    } = await getRequestByFirebaseToken<{ projects: ProjectType[] }>("portal/projects");

    return projects
        .filter((project) => project.privatekey)
        .map((project) => {
            const { icon } = JSON.parse(project.config.toString());
            const role = project.current_user_role.name;
            return {
                ...project,
                icon,
                role,
            };
        });
};

export { responseGetProjects };
