import { NavigateFunction } from "react-router/dist/lib/hooks";
import { Chip } from "@mui/material";
import React from "react";

import { ObjectType } from "@screens/Objects/Objects.types";
import { TableDataType } from "@components/Table/types";
import { ChipType } from "@screens/Pools/DiscoveryPool.types";
import { PermissionEnum } from "@screens/Objects/Object/Object.constants";

const projectHeader = [
    {
        isSort: true,
        heading: window.locales.namespace,
    },
    {
        isSort: true,
        heading: window.locales.auth,
    },
];

const accumulateProjects = (
    objects: ObjectType[],
    navigate: NavigateFunction,
    permissions: string[],
): TableDataType[] =>
    objects.map((object: ObjectType) => {
        const {
            metadata: { name },
            data,
        } = object;

        const status: ChipType = {
            label: window.locales.disabled,
            color: "error",
        };

        if (data) {
            status.label = window.locales.enabled;
            status.color = "success";
        }

        const userCanEdit = permissions?.includes(PermissionEnum.PUT);

        const row: TableDataType = {
            name,
            auth: {
                sortColumn: status.label,
                component: (
                    <Chip color={status.color} disabled={false} size="small" variant="filled" label={status.label} />
                ),
            },
            event: () => {
                if (userCanEdit) {
                    navigate(decodeURIComponent(name));
                }
            },
        };

        return row;
    });

export { accumulateProjects, projectHeader };
