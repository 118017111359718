import React from "react";
// @ts-ignore
import ReactCountryFlag from "react-country-flag";

import { TableDataType } from "@components/Table/types";
import { getCountryName } from "@common/methods";
import { INetwork } from "@screens/Network/Countries/types";
import { TargetIcon } from "@components/TargetIcon";
import iconTrash from "@common/img/svg/trash-icon.svg";
import * as tableStyle from "@components/Table/style.scss";

const headings = [
    {
        isSort: true,
        heading: window.locales.location,
    },
    {
        isSort: true,
        heading: window.locales.country,
    },
    {
        isSort: true,
        heading: window.locales.type,
    },
    {
        isSort: false,
        heading: "",
    },
];

const pattern = new RegExp("^[A-Za-z]{2}$");

const deleteItem = (changed: (arr: any[], item: any) => void, item: any, isEdit: boolean) =>
    (isEdit && (
        <div className={tableStyle.buttonsColumn}>
            <TargetIcon onClick={() => changed([], item)}>
                <img src={iconTrash} alt="img" />
            </TargetIcon>
        </div>
    )) || <div />;

const locationElem = (isValidCode: boolean, country: string) =>
    isValidCode ? (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
            }}
        >
            <ReactCountryFlag svg countryCode={country.toLowerCase()} style={{ fontSize: "1.5em" }} />
            {country.toUpperCase()}
        </div>
    ) : (
        <div />
    );

const accumulateCountries = (
    network: INetwork[],
    changed: (arr: any, item: any) => void,
    isEdit: boolean,
): TableDataType[] =>
    network
        .filter(({ visibility }) => visibility)
        .map((entries) => {
            const { country } = entries;
            const isValidCode = pattern.test(country);
            return {
                location: {
                    sortColumn: country.toLowerCase(),
                    component: locationElem(isValidCode, country.toLowerCase()),
                },
                country: getCountryName(country),
                type: window.locales.public,
                buttons: {
                    component: deleteItem(changed, entries, isEdit),
                },
            };
        });

const updateNetwork = (arr: INetwork[], country: string) =>
    arr.map((item) => {
        if (item.country === country) {
            return {
                ...item,
                visibility: false,
            };
        }
        return item;
    });

export { headings, accumulateCountries, updateNetwork };
