import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { loadEvents } from "@services/Event";
import { IconDownload, IconPlus } from "@common/icons";
import { RootState } from "@state/index";
import { accumulateEvents, downloadFile, headings } from "@screens/Log/utils";
import Table from "@components/Table";
import { pageViewsTracking } from "@common/gaTracking/pageViewsTracking";
import { TableDataType } from "@components/Table/types";
import { loadUsers } from "@services/Access/access.service";
import { AdminType } from "@services/Access/Access.types";

import { IBodyLoadEvensRequest, IEvent } from "./types";
import Filter from "./Filter/Filter";

const Log = () => {
    const [members, setMembers] = useState<AdminType[]>([]);
    const location = useLocation();
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const [eventsData, setEventsData] = useState<IEvent[]>([]);
    const [body, setBody] = useState<TableDataType[]>([]);
    const refCursor = useRef(0);
    const [params, setParams] = useState<IBodyLoadEvensRequest>({
        authors: [],
        start_time: 0,
        end_time: 0,
        types: [],
    });
    const title = window.locales.log;

    const getMembers = useCallback(async () => {
        if (activeProject.publickey) {
            const { data } = await loadUsers(activeProject.publickey);
            setMembers(data.admins);
        }
    }, [activeProject.publickey]);

    useEffect(() => {
        getMembers()
            .then()
            .catch((e) => window.console.error(e));
    }, [activeProject.publickey, getMembers]);

    const getEvents = useCallback(async () => {
        if (!activeProject.publickey) return;
        const { events = [], cursor = 0 } = await loadEvents(params);
        if (events.length >= 100) {
            refCursor.current = cursor;
        }
        setEventsData(events);
        setBody(accumulateEvents(events));
    }, [activeProject.publickey, params]);

    useEffect(() => {
        pageViewsTracking(location.pathname);
        getEvents().then();
    }, [getEvents, location.pathname]);

    const loadMore = async () => {
        const payload = {
            ...params,
            end_time: refCursor.current,
        };
        try {
            const events = await loadEvents(payload);
            setEventsData(events);
            setBody(accumulateEvents(events.events));
        } catch (error) {
            window.console.error(error);
        }
    };

    return (
        <div className="EventLog">
            <div className="head">
                <h1>{window.locales.log}</h1>
            </div>
            <Table tableData={body} headings={headings} title={title} emptyMessage={window.locales.noEvents}>
                <div className="EventLogHeader">
                    <Filter admins={members} params={params} setParams={setParams} />
                    {body.length > 0 && (
                        <div onClick={() => downloadFile(refCursor.current, eventsData, activeProject)}>
                            <IconDownload theme="blue" label={window.locales.download} />
                        </div>
                    )}
                </div>
            </Table>
            {refCursor.current > 0 && (
                <div className="loadMore">
                    <IconPlus onClick={loadMore} label={window.locales.loadMore} theme="blue" />
                </div>
            )}
        </div>
    );
};

export default Log;
