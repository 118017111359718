import React from "react";

import { TableDataType } from "@components/Table/types";
import { TargetIcon } from "@components/TargetIcon";
import { TRowLocations } from "@screens/Network/Pool/Locations/types";
import * as table from "@components/Table/style.scss";
import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";
import LabelsCountry from "@components/LabelsCountry";
import { LabelsServer } from "@components/LabelsServer";

const accumulatePools = (
    locations: any[],
    onEdit: (location: any) => void,
    onDelete: (location: any) => void,
    canWrite: any,
): TableDataType[] =>
    locations.map((location) => {
        const { name, labels, description, server_selector } = location;
        const { city, country } = labels;
        const row: TRowLocations = {
            name,
            labels: {
                sortColumn: city,
                component: LabelsCountry(country, city),
            },
            server_selector: {
                component: LabelsServer(server_selector),
            },
            description,
            buttons: { component: <div /> },
        };

        if (canWrite) {
            row.buttons = {
                component: (
                    <div className={table.buttonsColumn}>
                        <TargetIcon onClick={() => onEdit(location)}>
                            <img src={iconPen} alt="img" />
                        </TargetIcon>
                        <TargetIcon onClick={() => onDelete(location)}>
                            <img src={iconTrash} alt="img" />
                        </TargetIcon>
                    </div>
                ),
            };
        }

        return row;
    });

const headings = [
    {
        isSort: true,
        heading: "Name",
    },
    {
        isSort: false,
        heading: "Labels",
    },
    {
        isSort: false,
        heading: "Server selector",
    },
    {
        isSort: false,
        heading: "Description",
    },
    {
        isSort: false,
        heading: "",
    },
];

export { accumulatePools, headings };
