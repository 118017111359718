import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import { GetMetricsRequest } from "@services/Graphs";
import { Empty } from "@components/Empty";
import * as style from "@screens/Dashboard/style.scss";
import DateFilter from "@components/DateFilter/DateFilter";
import { GraphDataType } from "@screens/Dashboard/GeoChart/GeoChart.types";

import graphsMetadata from "../../graphs.config.json";
import { StackedAreaChart } from "./Graphs";

const getDate = ({ startDate, endDate }: { startDate: Date; endDate: Date }, time: number) => {
    const date = new Date(time * 1000);
    const oneDay = 24 * 60 * 60 * 1000;
    const days = Math.round(Math.abs((Number(startDate) - Number(endDate)) / oneDay));

    return days === 1
        ? date.toLocaleTimeString().slice(0, -3)
        : `${date.getMonth() + 1}/${date.getDate()} ${date.toLocaleTimeString().slice(0, -3)}`;
};

type TMetadata = {
    entity: string;
    identity: string;
    items: string[];
    visible: boolean;
    stacked: boolean;
    info: string;
    points: { [key: string]: string | number }[];
};

const getDataGraphs =
    (graphsMetadata: TMetadata[]) =>
    (responses: GraphDataType[], graphIndex: number, period: { startDate: Date; endDate: Date }) => {
        let points: Array<Record<string, string | number>> = [];

        responses.forEach((dataMetric, index) => {
            if (responses.flat().length === 0) {
                points = [];
            }

            dataMetric &&
                dataMetric.forEach(({ metric, values }) => {
                    const [value] = Object.keys(metric);
                    let nameValue = value || graphsMetadata[graphIndex].items[index];
                    if (nameValue === "active_sessions_by_location") {
                        nameValue = "Undefined";
                    }

                    if (points.length === 0) {
                        values.forEach((item) => {
                            const [time, value] = item;
                            points.push({
                                name: getDate(period, time),
                                [nameValue]: Number(value),
                            });
                        });
                    } else {
                        values.forEach((item, i) => {
                            const [time, value] = item;
                            try {
                                points[i][nameValue] = Number(value);
                            } catch (e) {
                                points.push({
                                    name: getDate(period, Number(time)),
                                    [nameValue]: Number(value),
                                });
                            }
                        });
                    }
                });

            if (points.length > 0) {
                graphsMetadata[graphIndex].points = points;
                graphsMetadata[graphIndex].visible = true;
            } else {
                graphsMetadata[graphIndex].visible = false;
            }

            graphsMetadata[graphIndex].entity =
                dataMetric && dataMetric.length > 0 ? Object.keys(dataMetric[0].metric)[0] : "";
        });

        return [...graphsMetadata];
    };

const GeneralGraphs = () => {
    const [dataGraphs, setDataGraphs] = useState<TMetadata[]>(graphsMetadata.general);
    const componentIsMounted = useRef(true);
    const { project = "" } = useParams();
    const [period, setPeriod] = useState<[Date, Date]>(() => {
        const now = new Date();
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
        return [yesterday, now];
    });

    useEffect(() => {
        componentIsMounted.current = true;
    }, []);

    useLayoutEffect(() => {
        const { general } = graphsMetadata;
        setDataGraphs(general);
    }, [project]);

    useEffect(() => {
        const { general } = graphsMetadata;
        setDataGraphs(general);

        const requests = general.map((graphMetadata) =>
            graphMetadata.items.map((graphId) => {
                const [startDate, endDate] = period;
                return GetMetricsRequest(project, graphId, {
                    startDate,
                    endDate,
                });
            }),
        );

        const callRequests = () => {
            const getData = getDataGraphs(general);
            requests.forEach(async (request, graphIndex) => {
                try {
                    const responses = await Promise.all(request);
                    const [startDate, endDate] = period;
                    const data = getData(responses, graphIndex, {
                        startDate,
                        endDate,
                    });
                    componentIsMounted.current && setDataGraphs([...data]);
                } catch {
                    setDataGraphs([]);
                }
            });
        };

        callRequests();

        return () => {
            componentIsMounted.current = false;
        };
    }, [project, period]);

    const filteredData = useMemo(() => dataGraphs.filter((dataGraph) => dataGraph.visible), [dataGraphs]);

    if (!project) {
        return <Navigate replace to="/projects" />;
    }

    const changePeriod = async (value: [Date?, Date?] | null) => {
        if (!value) {
            return;
        }

        const [firstData, secondDate] = value;
        if (!firstData || !secondDate) {
            return;
        }
        setPeriod([firstData, secondDate]);
    };

    return (
        <>
            <div className={style.filterWrapper}>
                Period: <DateFilter period={period} changePeriod={changePeriod} />
            </div>
            <div className={style.graphContainer}>
                {filteredData.length > 0 ? (
                    filteredData.map((dataGraph, index) => (
                        <StackedAreaChart
                            stacked={dataGraph.stacked}
                            key={index}
                            data={dataGraph.points}
                            id={dataGraph.identity}
                            info={dataGraph.info}
                        />
                    ))
                ) : (
                    <Empty message={window.locales.noData} />
                )}
            </div>
        </>
    );
};

export default GeneralGraphs;
