import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { accumulateProjects, headings } from "@screens/VPN/VPN.utils";
import Table from "@components/Table";
import { RootState } from "@state/index";
import { Search } from "@components/Search/Search";
import { resetActiveProject } from "@state/activeProject";
import * as commonStyle from "@common/style/style.scss";
import { useFilteredData } from "@common/hooks/useFilteredData";

import { ProjectType } from "./VPN.types";

const filterFunction =
    (searchValue: string) =>
    ({ publickey = "", carrier_name = "" }: ProjectType): boolean =>
        publickey.includes(searchValue) || carrier_name.includes(searchValue);

const VPNList = () => {
    const projects: ProjectType[] = useSelector((state: RootState) => state.projects);
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const navigate = useNavigate();
    const projectList = useMemo(() => Object.entries(projects).map(([, value]) => value), [projects]);
    const { setData, setSearch, filteredList } = useFilteredData<ProjectType>(filterFunction);

    const dispatch = useDispatch();

    useEffect(() => {
        if (activeProject.publickey) {
            dispatch(resetActiveProject());
        }
    }, [dispatch, activeProject.publickey]);

    useEffect(() => {
        if (!projectList.length) return;
        setData(projectList);
    }, [projectList, setData]);

    return (
        <div className={commonStyle.mainPageContainer}>
            <Table
                tableData={accumulateProjects(filteredList, navigate)}
                headings={headings}
                title="projects"
                emptyMessage={window.locales.noProjects}
            >
                <Search callback={setSearch} />
            </Table>
        </div>
    );
};

export default VPNList;
