import { call, put, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { PayloadAction } from "@reduxjs/toolkit";

import { responseGetProjects } from "@services/Projects";
import { editActiveProject, getActiveProject, setActiveProject, updateActiveProject } from "@state/activeProject";
import { setPermissions } from "@state/permissions";
import { loadLicenses } from "@services/Licenses";
import { editProject } from "@services/Project";
import { setToken } from "@state/token";
import { IErrorResponse, IActiveProject } from "@interfaces";
import { signIn } from "@services/Users/Users";
import { errorInfo } from "@state/error";
import { successInfo } from "@state/success";
import { ProjectType } from "@screens/VPN/VPN.types";

import { getProjects, setProjects } from "./index";

export function* getProjectsSaga(): SagaIterator {
    const projects = yield call(() => responseGetProjects());
    yield put(setProjects(projects));
}

export function* getProjectSaga(action: PayloadAction<IActiveProject>): SagaIterator {
    const activeProject = { ...action.payload };
    if (!activeProject.publickey) return;

    yield put(setPermissions(activeProject.current_user_role.permissions));
    const { data } = yield call(signIn, activeProject.publickey, activeProject.privatekey);
    yield put(setToken(data));

    if (!data.access_token && (data.result === "REQUEST_LIMIT_EXCEED" || data.result === "NOT_AUTHORIZED")) {
        yield put(setToken(data.result));
        return;
    }

    const { response } = yield call(() => loadLicenses(data.access_token));
    const { licenses } = response;
    const config = JSON.parse(activeProject.config);
    yield put(
        setActiveProject({
            ...activeProject,
            config,
            licenses,
        }),
    );
}

export function* updateProjectSaga(action: PayloadAction<string>): SagaIterator {
    const projects = yield call(() => responseGetProjects());
    const project = projects.find((projects: IActiveProject) => projects.publickey === action.payload);
    yield put(getActiveProject(project));
}

export function* editActiveProjectSaga(action: PayloadAction<ProjectType>): SagaIterator {
    try {
        const config = JSON.stringify(action.payload.config);
        const { data } = yield call(editProject, {
            ...action.payload,
            config,
        });
        if (data) {
            yield put(getActiveProject(data.project));
            successInfo.setSuccessInfo();
        }
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export const ProjectsSagas = [
    takeEvery(getProjects.type, getProjectsSaga),
    takeEvery(getActiveProject.type, getProjectSaga),
    takeEvery(updateActiveProject.type, updateProjectSaga),
    takeEvery(editActiveProject.type, editActiveProjectSaga),
];
