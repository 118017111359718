import Network from "@common/Network";
import { getPortalTokenFromState } from "@common/getPortalTokenFromState";
import { postUrlencodedRequestByToken } from "@common/requests/requestByPortalToken";

const loadPurchases = (uid: string) =>
    new Promise((resolve, reject) => {
        const request = `partner/subscribers/${uid}/purchase`;
        const access_token = getPortalTokenFromState();

        Network.get(request, {}, access_token)
            .then((response) => resolve(response.response.purchases))
            .catch(() => reject());
    });

const deletePurchases = (uid: string, pid: string) =>
    new Promise((resolve, reject) => {
        const request = `partner/subscribers/${uid}/purchase`;
        const access_token = getPortalTokenFromState();
        const params = {
            purchase_id: pid,
        };

        Network.delete(request, params, null, access_token)
            .then((response) => {
                if (response.response.result === "NOT_FOUND") {
                    return reject({ message: "Purchases not found" });
                }
                return resolve(true);
            })
            .catch(() => reject({ message: "Internal server error" }));
    });

const addPurchase = (user_id: string, type: string, token: string) => {
    return postUrlencodedRequestByToken(
        `/partner/subscribers/${user_id}/purchase
`,

        {
            type,
            token,
        },
    );
};

const activatePurchase = (pid: string, publickey: string) =>
    new Promise((resolve, reject) => {
        const request = "portal/purchase/recovery";
        const access_token = getPortalTokenFromState();
        const params = {
            publickey,
            purchase_history_id: pid,
        };

        Network.post(request, params, null, access_token)
            .then(() => resolve(true))
            .catch(() => reject({ message: "Internal server error" }));
    });

export { loadPurchases, deletePurchases, activatePurchase, addPurchase };
