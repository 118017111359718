import qs from "qs";
import axios from "axios";

import { getPortalTokenFromState } from "@common/getPortalTokenFromState";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";
import { ParametersType, PutParamsType } from "@common/requests/types";
import { refreshToken } from "@common/requests/utils";

axios.defaults.baseURL = `/api/${process.env.CURRENT_API}/`;
const putUrlencodedRequestByToken = async <T>(url: string, parameters: PutParamsType): Promise<T> => {
    try {
        const access_token = getPortalTokenFromState();
        const data = qs.stringify(parameters);
        return await axios.put(url, data, {
            headers: {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    } catch (e: any | IErrorResponse) {
        refreshToken(e.response.status);
        errorInfo.setErrorInfo({
            title: e.response.status,
            description: e.response.statusText,
        });
        return e;
    }
};

const postUrlencodedRequestByToken = async <T>(url: string, parameters: PutParamsType): Promise<T> => {
    try {
        const access_token = getPortalTokenFromState();
        const data = qs.stringify(parameters);
        return await axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    } catch (e: any | IErrorResponse) {
        refreshToken(e.response.status);
        errorInfo.setErrorInfo({
            title: e.response.status,
            description: e.response.statusText,
        });
        return e;
    }
};

const getRequestByToken = async <T>(url: string, parameters: ParametersType): Promise<T> => {
    try {
        const access_token = getPortalTokenFromState();
        return await axios.get(url, {
            headers: { Authorization: `Bearer ${access_token}` },
            params: {
                ...parameters,
                t: Number(new Date()),
            },
        });
    } catch (e: any | IErrorResponse) {
        const error: IErrorResponse = e;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
        return e;
    }
};

export { getRequestByToken, putUrlencodedRequestByToken, postUrlencodedRequestByToken };
