import React from "react";

import { getDateString } from "@common/methods";
import { TargetIcon } from "@components/TargetIcon";
import { DataItem } from "@components/Table/utils";
import { EModals, ILicenseKey, ILicenseKeyModal, TAction } from "@screens/LicenseKeys/types";
import * as tableStyle from "@components/Table/style.scss";
import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";
import { TableDataType } from "@components/Table/types";

const getDataTableLicenseKeys = (
    licenseKeys: ILicenseKey[],
    action: (type: EModals, licenseKey: ILicenseKey) => void,
): TableDataType[] =>
    licenseKeys.map((licenseKey) => {
        const { token, metadata, expire_time, create_time, update_time } = licenseKey;
        const expireTime = (expire_time && getDateString(expire_time)) || {
            date: "",
            time: "",
        };
        const createTime = (create_time && getDateString(create_time)) || {
            date: "",
            time: "",
        };
        const updateTime = (update_time && getDateString(update_time)) || {
            date: "",
            time: "",
        };
        const stringMetadata = JSON.stringify(metadata);

        const buttons = {
            component: (
                <div className={tableStyle.buttonsColumn}>
                    <TargetIcon onClick={() => action(EModals.EditKey, licenseKey)}>
                        <img src={iconPen} alt="img" />
                    </TargetIcon>
                    <TargetIcon onClick={() => action(EModals.DeleteKey, licenseKey)}>
                        <img src={iconTrash} alt="img" />
                    </TargetIcon>
                </div>
            ),
        };

        return {
            create_time: {
                sortColumn: create_time || 0,
                component: <DataItem {...createTime} />,
            },
            update_time: {
                sortColumn: update_time || 0,
                component: <DataItem {...updateTime} />,
            },
            token,
            metadata: {
                component: <div className={tableStyle.tdJson}>{stringMetadata}</div>,
            },
            expire_time: {
                sortColumn: expire_time || 0,
                component: <DataItem {...expireTime} />,
            },
            buttons,
        };
    });

const initialStateModalLicenseKey = {
    editLicenseKeyModal: false,
    deleteLicenseKeyModal: false,
    createLicenseKeyModal: false,
    licenseKey: null,
};

const licenseKeysHeadings = [
    {
        isSort: true,
        heading: window.locales.create_time,
    },
    {
        isSort: true,
        heading: window.locales.update_time,
    },
    {
        isSort: true,
        heading: window.locales.token,
    },
    {
        isSort: false,
        heading: window.locales.metadata,
    },
    {
        isSort: true,
        heading: window.locales.expire_time,
    },
    {
        isSort: false,
        heading: "",
    },
];

const reducerKeys = (state: ILicenseKeyModal, action: TAction) => {
    switch (action.type) {
        case EModals.EditKey:
            return {
                ...state,
                editLicenseKeyModal: true,
                licenseKey: action.payload,
            };
        case EModals.DeleteKey:
            return {
                ...state,
                deleteLicenseKeyModal: true,
                licenseKey: action.payload,
            };
        case EModals.CreateKey:
            return {
                ...state,
                createLicenseKeyModal: true,
                licenseKey: action.payload,
            };
        case EModals.CloseModals:
            return initialStateModalLicenseKey;
        default:
            return initialStateModalLicenseKey;
    }
};

const initLicenseKey = {
    token: "",
    metadata: "",
    expire_time: 0,
};

export { getDataTableLicenseKeys, reducerKeys, licenseKeysHeadings, initialStateModalLicenseKey, initLicenseKey };
