import React, { FC, useState, useMemo, useRef, useEffect, memo } from "react";

import { compare, getHeader } from "@components/Table/utils";
import { TableHeader } from "@components/Table/TableHeader";
import { TableBody } from "@components/Table/TableBody";
import { Empty } from "@components/Empty";
import { TableDataType, TableProps, ESort } from "@components/Table/types";
import * as tableContainerStyle from "@components/TableContainer/style.scss";

import * as style from "./style.scss";

const viewOptions = (title: string) => {
    if (title) {
        return window.storage.get(title);
    }
    return null;
};

const Table: FC<TableProps> = memo(({ tableData, headings, title, emptyMessage, children }) => {
    const [sortingRowData, setSortingRowData] = useState<TableDataType[] | null>(null);
    const sortOptions = useRef(
        viewOptions(title) || {
            heading: getHeader(headings[0].heading),
            type: ESort.Up,
        },
    );

    useEffect(() => {
        setSortingRowData(
            [...tableData].sort((a, b) =>
                compare(a[sortOptions.current.heading], b[sortOptions.current.heading], sortOptions.current.type),
            ),
        );
    }, [tableData]);

    const sortData = useMemo(
        () =>
            (heading: string, type: ESort): void => {
                if (
                    (sortOptions.current.type !== type && sortOptions.current.heading === heading) ||
                    sortOptions.current.heading !== heading
                ) {
                    const convertHeader = getHeader(heading);
                    setSortingRowData([...tableData].sort((a, b) => compare(a[convertHeader], b[convertHeader], type)));
                    sortOptions.current = {
                        heading: getHeader(heading),
                        type,
                    };
                    window.storage.set(title, {
                        heading: getHeader(heading),
                        type,
                    });
                }
                window.storage.set(title, {
                    heading: getHeader(heading),
                    type,
                });
            },
        [tableData, title],
    );

    return (
        <div className={tableContainerStyle.tableContainer}>
            <div className={style.tableContainer}>
                {children}
                {tableData.length > 0 ? (
                    <table className={style.styledTable}>
                        <TableHeader headings={headings} sort={sortData} sortOptions={sortOptions.current} />
                        <TableBody tableData={sortingRowData || tableData} isReduce={headings.length > 7} />
                    </table>
                ) : (
                    <Empty message={emptyMessage} />
                )}
            </div>
        </div>
    );
});

export default Table;
