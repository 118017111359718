import React from "react";

import { TableDataType } from "@components/Table/types";
import { byteConvert, getDateString } from "@common/methods";
import { TargetIcon } from "@components/TargetIcon";
import iconTrash from "@common/img/svg/trash-icon.svg";
import * as table from "@components/Table/style.scss";
import { IDevice, ITraffic } from "@screens/Users/User/Content/Devices/types";

const headingsType1 = [
    {
        isSort: true,
        heading: window.locales.did,
    },
    {
        isSort: false,
        heading: window.locales.accessToken,
    },
    {
        isSort: true,
        heading: window.locales.deviceType,
    },
    {
        isSort: true,
        heading: window.locales.registrationTime,
    },
];

const headingsType2 = [
    {
        isSort: true,
        heading: "Traffic limit",
    },
    {
        isSort: true,
        heading: "Traffic used",
    },
    {
        isSort: true,
        heading: "Traffic remaining",
    },
    {
        isSort: false,
        heading: "",
    },
];

const accumulateDevices = (
    devices: IDevice[],
    traffic: ITraffic = null,
    toggleDeleteModal: (id: string) => void,
): TableDataType[] =>
    devices.map((device) => {
        const { access_token, device_id, registration_time, type } = device;
        const trafficLimitDevices = (traffic && traffic.traffic_limit_devices) || null;
        const limit = byteConvert(trafficLimitDevices && trafficLimitDevices[device.device_id].limit);
        const used = byteConvert(trafficLimitDevices && trafficLimitDevices[device.device_id].used);
        const remaining = byteConvert(trafficLimitDevices && trafficLimitDevices[device.device_id].remaining);
        const date = getDateString(registration_time) || {
            date: "",
            time: "",
        };
        return {
            device_id: {
                tooltip: {
                    title: device_id,
                    content: device_id,
                },
            },
            access_token: {
                tooltip: {
                    title: access_token,
                    content: access_token,
                },
            },
            device_typ: type,
            registration_time: `${date.date} ${date.time}`,
            ...(trafficLimitDevices && { traffic_limit: [`${limit?.size} ${limit?.type}`] }),
            ...(trafficLimitDevices && { traffic_used: [`${used?.size} ${used?.type}`] }),
            ...(trafficLimitDevices && { traffic_remaining: [`${remaining?.size} ${remaining?.type}`] }),
            buttons: {
                component: (
                    <div className={table.buttonsColumn}>
                        <TargetIcon onClick={() => toggleDeleteModal(device_id)}>
                            <img src={iconTrash} alt="img" />
                        </TargetIcon>
                    </div>
                ),
            },
        };
    });

export { accumulateDevices, headingsType1, headingsType2 };
