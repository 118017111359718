import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

import Table from "@components/Table";
import { RootState } from "@state/index";
import { accumulateRules, headings } from "@screens/Settings/VPN/SNI/SNI.utils";
import { If } from "@components/If";
import * as settingsStyle from "@screens/Settings/style.scss";
import { ERoles } from "@screens/Settings/enums";
import { HydraRuleModal } from "@screens/Settings/VPN/SNI/RuleModal";
import { Modal } from "@components/Modal";
import { editActiveProject } from "@state/activeProject";
import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import { EModals } from "@components/Modal/types";
import * as poolStyle from "@screens/Network/Pool/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import { TableDataType } from "@components/Table/types";
import { IconPlus } from "@common/icons";

import { IRule } from "./SNI.types";

const defaultRule: IRule = {
    id: "",
    selector: {},
    patch: { sd: { SNIs: { default: [] } } },
    description: "",
};

const initialState = initialStateModal(defaultRule);
const reducerHydraRules = reducerModal(initialState);

const SNI = () => {
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const [body, setBody] = useState<TableDataType[]>([]);
    const [modals, dispatchModal] = useReducer(reducerHydraRules, initialState);
    const dispatch = useDispatch();

    useEffect(() => {
        if (activeProject.publickey) {
            const { config } = activeProject;
            const provide = config.provide || {};
            const rules = provide.rules || [];
            const parsedHydraConfig = accumulateRules(rules, showEditModal, showDeleteModal);
            setBody(parsedHydraConfig);
        }
    }, [activeProject]);

    const showEditModal = (rule: IRule) => {
        dispatchModal({
            type: EModals.EditModal,
            payload: rule,
        });
    };

    const showDeleteModal = (rule: IRule) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: rule,
        });
    };

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: defaultRule,
        });
    };

    const addRule = (rule: IRule) => {
        if (activeProject.publickey) {
            const { config } = activeProject;
            const { provide = { rules: [] } } = config;
            const { rules } = provide;
            const cloneRules: IRule[] = cloneDeep(rules);

            cloneRules.push(rule);

            dispatch(
                editActiveProject({
                    ...activeProject,
                    config: {
                        ...config,
                        provide: {
                            ...provide,
                            rules: cloneRules,
                        },
                    },
                }),
            );
        }
    };

    const editRule = (rule: IRule) => {
        if (activeProject.publickey) {
            const { config } = activeProject;
            const { provide = { rules: [] } } = config;
            const { rules } = provide;
            const cloneRules: IRule[] = cloneDeep(rules);
            const indexRule = cloneRules
                .filter((value: IRule) => value)
                .findIndex(({ id }: IRule) => id && id === rule.id);
            indexRule === -1 ? cloneRules.push(rule) : (cloneRules[indexRule] = rule);

            dispatch(
                editActiveProject({
                    ...activeProject,
                    config: {
                        ...config,
                        provide: {
                            ...provide,
                            rules: cloneRules,
                        },
                    },
                }),
            );
        }
    };

    const deleteRule = () => {
        if (activeProject && modals.item.id) {
            const { config } = activeProject;
            const { provide = { rules: [] } } = config;
            const { rules } = provide;
            const cloneRules = cloneDeep(rules);
            const indexRule = cloneRules
                .filter((value: IRule) => value)
                .findIndex(({ id }: IRule) => id && id === modals.item.id);
            cloneRules.splice(indexRule, 1);
            closeModal();

            dispatch(
                editActiveProject({
                    ...activeProject,
                    config: {
                        ...config,
                        provide: {
                            ...provide,
                            rules: cloneRules,
                        },
                    },
                }),
            );
        }
    };

    return (
        <div className={settingsStyle.tabContainer}>
            <Table tableData={body} headings={headings} title="Hydra config" emptyMessage={window.locales.noRules}>
                <If condition={activeProject?.role !== ERoles.Support}>
                    <div className={poolStyle.extraButtons}>
                        <button
                            className={buttonStyle.buttonAdd}
                            type="submit"
                            onClick={() =>
                                dispatchModal({
                                    type: EModals.CreateModal,
                                    payload: defaultRule,
                                })
                            }
                        >
                            <IconPlus theme="blue" />
                            {window.locales.add}
                        </button>
                    </div>
                </If>
            </Table>

            {/* Add hydra rule */}
            <HydraRuleModal
                rule={modals.item}
                title={window.locales.addRule}
                close={closeModal}
                isOpen={modals.createModal}
                action={addRule}
                buttonName={window.locales.confirm}
            />

            {/* Edit hydra rule */}
            <HydraRuleModal
                rule={modals.item}
                title={window.locales.editRule}
                close={closeModal}
                isOpen={modals.editModal}
                action={editRule}
                buttonName={window.locales.confirm}
            />

            {/* Modal deleting server pool */}
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                title={window.locales.deleteRule}
                onClose={closeModal}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteRule,
                }}
            >
                Do you really want to delete rule?
            </Modal>
        </div>
    );
};

export default SNI;
