import Network from "@common/Network";
import { getPortalTokenFromState } from "@common/getPortalTokenFromState";

const getLicenseKeysRequest = () => {
    const access_token = getPortalTokenFromState();
    const request = "partner/license_key/list";

    return Network.get(request, {}, access_token);
};

const createLicenseKeyRequest = (licenseKey: Record<string, unknown>) => {
    const access_token = getPortalTokenFromState();
    const request = "partner/license_key";

    return Network.post(request, {}, licenseKey, access_token);
};

const deleteLicenseKeyRequest = (token: string) => {
    const access_token = getPortalTokenFromState();
    const request = "partner/license_key";

    return Network.delete(request, {}, { token }, access_token);
};

export { getLicenseKeysRequest, createLicenseKeyRequest, deleteLicenseKeyRequest };
