import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ObjectEnum, PermissionEnum } from "@screens/Objects/Object/Object.constants";

const objectPermissionsSlice = createSlice({
    name: "objectPermissions",
    initialState: { [ObjectEnum.UNKNOWN]: [PermissionEnum.GET] } as Record<ObjectEnum, PermissionEnum[]>,
    reducers: {
        setObjectPermissions(state, action: PayloadAction<Record<ObjectEnum, PermissionEnum[]>>) {
            return action.payload;
        },
    },
});

export const { setObjectPermissions } = objectPermissionsSlice.actions;

export default objectPermissionsSlice.reducer;
