import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";

import Table from "@components/Table";
import { RootState } from "@state/index";
import { accumulatePermissionPools, poolHeadings } from "@screens/Network/Pools/Pools.utils";
import PoolModal from "@screens/Network/Pools/PoolModal";
import { TableDataType } from "@components/Table/types";
import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import { IPermission, IPool } from "@screens/Network/Pools/types";
import { EModals } from "@components/Modal/types";
import { changePool } from "@state/pools";
import { deleteDuplicateInObject } from "@common/methods";

const initPool: IPool = {
    name: "",
    description: "",
    locations: null,
    groups: [],
    permissions: [],
    version: 0,
    selector: { project: { in: [] } },
};

const initialState = initialStateModal(initPool);
const reducerLocation = reducerModal(initialState);

const Pools = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const [body, setBody] = useState<TableDataType[]>([]);
    const pools = useSelector((state: RootState) => state.pools);
    const [modals, dispatchModal] = useReducer(reducerLocation, initialState);

    const openPoolPage = useCallback(
        (name: string) => {
            navigate(`/projects/${activeProject.publickey}/network/pools/${name}`);
        },
        [activeProject.publickey, navigate],
    );

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: initPool,
        });
    };

    const showEditModal = useCallback((pool: IPool) => {
        const customPool: IPool = cloneDeep(pool);
        customPool.permissions.forEach((permission) => {
            permission.list = Object.entries(permission)
                .filter(([, value]) => typeof value === "boolean" && value)
                .map(([key]) => key);
        });

        dispatchModal({
            type: EModals.EditModal,
            payload: customPool,
        });
    }, []);

    const editPool = (pool: IPool) => {
        const content: { [key: string]: any } = {};
        const permissions = deleteDuplicateInObject(pool.permissions, "subject");

        if (modals.item.name !== pool.name) {
            content.name = pool.name;
        }

        if (modals.item.description !== pool.description) {
            content.description = pool.description;
        }

        let permissionItem: IPermission[] | null;

        const changedAddPermission: IPermission[] = permissions.filter((permission) => {
            const foundPermission = modals.item.permissions.find((item) => item.subject === permission.subject);
            return foundPermission && foundPermission.list && foundPermission.list.length !== permission.list.length;
        });

        if (changedAddPermission.length === 0) {
            permissionItem = permissions.filter(
                (permission) => !modals.item.permissions.some((item) => item.subject === permission.subject),
            );
        } else {
            permissionItem = changedAddPermission;
        }

        const add_permissions = permissionItem
            .filter(({ list }) => Array.isArray(list))
            .map(({ subject, list }) => {
                const values =
                    list &&
                    list.reduce(
                        (previousValue, currentValue) => ({
                            ...previousValue,
                            [currentValue]: true,
                        }),
                        {
                            can_read: false,
                            can_write: false,
                            can_use: false,
                        },
                    );

                return {
                    subject,
                    ...values,
                };
            });

        if (add_permissions.length > 0) {
            content.add_permissions = add_permissions;
        }

        const del_permissions = modals.item.permissions
            .filter((permission) => !permissions.some((item) => item.subject === permission.subject))
            .map(({ subject }) => subject);

        if (del_permissions.length > 0) {
            content.del_permissions = del_permissions;
        }

        dispatch(
            changePool({
                pool: pool.name,
                body: content,
            }),
        );
    };

    useEffect(() => {
        const content = accumulatePermissionPools(
            [...pools.privatePools, ...pools.publicPools],
            activeProject.publickey,
            showEditModal,
            openPoolPage,
        );
        setBody(content);
    }, [activeProject.publickey, openPoolPage, pools, showEditModal]);

    return (
        <>
            <Table tableData={body} headings={poolHeadings} title="Pools" emptyMessage={window.locales.noPools} />
            <PoolModal
                title={window.locales.editPool}
                action={editPool}
                isOpen={modals.editModal}
                item={modals.item}
                close={closeModal}
                buttonName={window.locales.confirm}
            />
        </>
    );
};

export default Pools;
