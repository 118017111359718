import { call, put, select, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";

import RootState from "@state/interfaces";
import { getPurchases, setPurchases } from "@state/purchases/index";
import { loadPurchases } from "@services/Purchases";

const selectUser = (state: RootState) => state.users.selectedUser;

export function* getPurchasesSaga(): SagaIterator {
    const user = yield select(selectUser);
    if (!user) {
        return;
    }

    const response = yield call(() => loadPurchases(user.id));
    yield put(setPurchases(response));
}

export const PurchasesSagas = [takeEvery(getPurchases.type, getPurchasesSaga)];
