import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Table from "@components/Table";
import { loadActiveSessions, loadSessions } from "@services/Sessions";
import RootState from "@state/interfaces";
import { TableDataType } from "@components/Table/types";
import DateFilter from "@components/DateFilter/DateFilter";
import { ISession } from "@screens/Users/User/Content/Sessions/types";
import { accumulateSessions, headings } from "@screens/Users/User/Content/Sessions/utils";

function Sessions() {
    const selectedUser = useSelector((state: RootState) => state.users.selectedUser);
    const [dataSessions, setDataSessions] = useState<ISession[]>([]);
    const [body, setBody] = useState<TableDataType[]>([]);
    const [period, setPeriod] = useState<[Date, Date]>(() => {
        const now = new Date();
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
        return [yesterday, now];
    });

    useEffect(() => {
        const data = accumulateSessions(dataSessions);
        setBody(data);
    }, [dataSessions]);

    const changePeriod = async (value: [Date?, Date?] | null) => {
        if (!value) {
            return;
        }

        const [firstData, secondDate] = value;
        if (!firstData || !secondDate) {
            return;
        }
        setPeriod([firstData, secondDate]);
    };

    const getSessions = useCallback(async () => {
        if (!selectedUser.id) return;
        const [startTime, endTime] = period;
        const sessions = await loadSessions(selectedUser.id, +startTime, +endTime);
        const activeSessions = await loadActiveSessions(selectedUser.id);
        setDataSessions([...sessions, ...activeSessions]);
    }, [period, selectedUser.id]);

    useEffect(() => {
        getSessions().then();
    }, [getSessions]);

    return (
        <>
            <Table
                tableData={body}
                headings={headings}
                title={window.locales.sessions}
                emptyMessage={window.locales.noSessionsRow1}
            >
                <DateFilter period={period} changePeriod={changePeriod} />
            </Table>
        </>
    );
}

export default Sessions;
