import React from "react";

import { TableDataType } from "@components/Table/types";
import { TargetIcon } from "@components/TargetIcon";
import * as table from "@components/Table/style.scss";
import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";

import { IClientNetworks } from "./types";

const map: { [key: string]: string } = {
    wifi: "WiFi",
    wwan: "WWAN",
    lan: "LAN",
    enable: window.locales.enable,
    disable: window.locales.disable,
    yes: window.locales.yes,
    no: window.locales.no,
};

const accumulateClientNetworks = (
    data: IClientNetworks[],
    onEdit: (network: IClientNetworks) => void,
    onDelete: (network: IClientNetworks) => void,
): TableDataType[] =>
    data.map((item, index) => {
        const { action = "", ssid = [], bssid = [], type = "", authorized = "" } = item;

        return {
            type: map[type] || window.locales.any,
            bssid: bssid.join(", ") || window.locales.any,
            ssid: ssid.join(", ") || window.locales.any,
            action: map[action] || action,
            authorized: map[authorized] || window.locales.any,
            buttons: {
                sortColumn: index,
                component: (
                    <div className={table.buttonsColumn}>
                        <TargetIcon
                            onClick={() =>
                                onEdit({
                                    id: index,
                                    ...item,
                                })
                            }
                        >
                            <img src={iconPen} alt="img" />
                        </TargetIcon>
                        <TargetIcon
                            onClick={() =>
                                onDelete({
                                    id: index,
                                    ...item,
                                })
                            }
                        >
                            <img src={iconTrash} alt="img" />
                        </TargetIcon>
                    </div>
                ),
            },
        };
    });

const headings = [
    {
        isSort: true,
        heading: window.locales.type,
    },
    {
        isSort: false,
        heading: window.locales.BSSID,
    },
    {
        isSort: false,
        heading: window.locales.SSID,
    },
    {
        isSort: true,
        heading: window.locales.action,
    },
    {
        isSort: false,
        heading: window.locales.authorized,
    },
    {
        isSort: false,
        heading: "",
    },
];

export { accumulateClientNetworks, headings };
